import { red } from '@material-ui/core/colors';
import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            light: '#649cb5',
            main: '#499EBF',
            dark: '#004358',
        },
        secondary: {
            light: '#5f6215',
            main: '#595f00',
            dark: '#a0a34b',

            // light: '#013250',
            // main: '#024873',
            // dark: '#024873',
        },
        error: {
            main: red.A200,
        },
    },
    spacing: 8,
    ratingStar: {
        color: '#898C1F',
    },
});

export default theme;
