import React from 'react';
import InvestmentUniverse from '../../components/InvestmentUniverse/InvestmentUniverse';

function InvestmentUniversePage() {
    return (
        <div>
            <InvestmentUniverse />
        </div>
    );
}

export default InvestmentUniversePage;
