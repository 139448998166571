import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide/Slide';
import AddEditCompetitor from './AddEditCompetitor';
import {addCompetitor, deleteCompetitor, updateCompetitor} from "../../../../../actions/researchActions";
import CompetitorCollapsibleTable from "./CompetitorCollapsibleTable";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        color: '#649cb5',
        width: '100%',
        textAlign: 'center',
        paddingBottom: 8,
        paddingTop: 8,
    },
}));

const Competitor = ({ loading,
                                error,
                                competitors,
                                addCompetitor,
                                updateCompetitor,
                                deleteCompetitor}) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    const [isAddMode, setIsAddMode] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({ idk_stock_competitors: 0 });
    const [showAddEdit, setShowAddEdit] = React.useState(false);

    const handleAddEditClicked = (event) => {

        if (event.currentTarget.parentNode.parentNode.dataset.id) {
            let id = event.currentTarget.parentNode.parentNode.dataset.id;

            //grab score to send to add/edit
            let comp = competitors.filter(
                (comp) => String(comp.idk_stock_competitors) === id
            );

            setInitialValues(comp[0]);
            setShowAddEdit(true);
            setIsAddMode(false);
        } else {
            setInitialValues({});
            setShowAddEdit(true);
            setIsAddMode(true);
        }
    };

    function onSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (isAddMode) {
            createInvCompetitor(values, resetForm);
        } else {
            updateInvCompetitor(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteCompetitor(values);
        setInitialValues({});
    }

    function createInvCompetitor(values, resetForm) {
        addCompetitor(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    function updateInvCompetitor(values, resetForm) {
        updateCompetitor(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    const handleCancel = () => {
        console.log('Cancel');
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    };

    return (
        <div>
            <div className={classes.title}>
                <Typography variant="h6">Competitor</Typography>
            </div>
            {!showAddEdit ? (
                <div>
                    <CompetitorCollapsibleTable
                        mobile={mobile}
                        competitorList={competitors}
                        handleAddEditClicked={handleAddEditClicked}
                    />
                </div>
            ) : (
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div>
                        <AddEditCompetitor
                            mobile={mobile}
                            initialValues={initialValues}
                            setShowAddEdit={setShowAddEdit}
                            onSubmit={onSubmit}
                            handleDelete={handleDelete}
                            handleCancel={handleCancel}
                        />
                    </div>
                </Slide>
            )}
        </div>
    );
};

Competitor.propTypes = {};

const mapStateToProps = (state) => ({
    loading: state.research.competitorData.loading,
    error: state.research.competitorData.error,
    competitors: state.research.competitorData.competitors,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addCompetitor: (invComp) => dispatch(addCompetitor(invComp)),
        updateCompetitor: (invComp) => dispatch(updateCompetitor(invComp)),
        deleteCompetitor: (invComp) => dispatch(deleteCompetitor(invComp))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Competitor);
