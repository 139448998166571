import React from 'react';
import { withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import './Analysis.css';

InvestmentUniverseGrid.propTypes = {

};

function InvestmentUniverseGrid(props) {
    //const [gridSetting, setGridSetting] = React.useState({
    const [gridSetting] = React.useState({
        columnDefs: [
            {
                headerName: '',
                children: [
                    {
                        headerName: 'ID',
                        field: 'idkInvestment',
                        width: 50,
                        //width: 80,
                        cellStyle: { textAlign: 'start' },
                    },
                    {
                        headerName: 'Ticker',
                        field: 'ticker',
                        //minWidth: 80,
                        width: 60,
                        resizable: true,
                        cellStyle: { textAlign: 'start' },
                    },
                    {
                        headerName: 'Name',
                        field: 'name',
                        width: 75,
                        resizable: true,
                    },
                    {
                        headerName: 'Score',
                        field: 'normScore',
                        width: 60,
                        resizable: true,
                    },
                    {
                        headerName: 'Evals',
                        field: 'scoreCnt',
                        width: 40,
                        resizable: true,
                    },
                    {
                        headerName: 'Source',
                        field: 'source',
                        width: 175,
                        resizable: true,
                    },
                    {
                        headerName: 'Pass/Fail',
                        field: 'passFail',
                        width: 125,
                        resizable: true,
                    },
                    {
                        headerName: 'Model Wt',
                        field: 'modelWt',
                        width: 50,
                        resizable: true,
                    },
                    {
                        headerName: 'Analyst',
                        field: 'analyst',
                        width: 10,
                        resizable: true,
                    },
                ],
            },
        ],
        rowSelection: 'single',
        components: {
      //      roundDecimalCellRenderer: roundDecimalCellRenderer,
        },
    })

// const roundDecimalCellRenderer = (param) => {
//     return param.value.toFixed(4);
// };

const onGridSizeChanged = (params) => {
    //let gridWidth = document.getElementById("grid-wrapper").offsetWidth;
    let gridWidth = document.getElementById('universeGrid').offsetWidth;

    console.log(gridWidth)

    if (gridWidth < 400) {
        params.columnApi.setColumnsVisible(['idkInvestment', 'ticker'], false);
    } else if (gridWidth > 400) {
        params.columnApi.setColumnsVisible(['idkInvestment', 'ticker'], true);
    }

    if (gridWidth < 1000) {
        params.columnApi.setColumnsVisible(['source', 'passFail'], false);
    } else if (gridWidth > 1000) {
        params.columnApi.setColumnsVisible(['source', 'passFail'], true);
    }

    if (gridWidth < 800) {
        params.columnApi.setColumnsVisible(['modelWt', 'analyst'], false);
    } else if (gridWidth > 800) {
        params.columnApi.setColumnsVisible(['modelWt', 'analyst'], true);
    }

    params.api.sizeColumnsToFit();
};

const onSelectionChanged = (params) => {
    let selectedRow = params.api.getSelectedRows()[0];

    console.log('Loading Details for ' + selectedRow.idkInvestment);

    props.history.push({
        pathname: `/universe/${selectedRow.idkInvestment}`,
    });
};

    return (
        <div
            id="universeGrid"
            className="grid-wrapper ag-theme-alpine-dark"
        >
            <AgGridReact
                columnDefs={gridSetting.columnDefs}
                rowData={props.rowData}
                rowSelection={gridSetting.rowSelection}
                //onGridReady={onGridReady}
                onGridSizeChanged={onGridSizeChanged}
                onSelectionChanged={onSelectionChanged}
                defaultColDef={{
                    sortable: true,
                    filter: true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars',
                    },
                }}
            />
        </div>
    );
}

export default withRouter(InvestmentUniverseGrid);