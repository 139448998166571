import React, { Fragment } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const useStyles = makeStyles((theme) => ({
    chart: {
        width: '90%',
        height: '90%',
        zIndex: 1,
    },
    paper: {
        padding: 2,
        color: theme.palette.primary,
    },
    color: theme.palette.primary,
    circle: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        backgroundColor: '#ffffff',
        color: '#424242',
        height: 70,
        width: 70,
        borderRadius: '50%',
        zIndex: 2,
        border: '5px solid #424242',
    },
}));

const TransScoreChartContainer = ({transScoreData}) => {
    const classes = useStyles();
    const stockTransScore = transScoreData.stockTransScore;
    const totalScore = Math.round(stockTransScore.normScore * 10) /10;
    const scores = stockTransScore.scores;

    const scoreMap = {}
    const orderedScoreMap = {}
    const score = [];
    const labels = [];
    const colors = [];
    const colorStack = ['#a0a34b','#499EBF','#649cb5','#a0a34b','#649cb5','#499EBF','#a0a34b',];
    //const colorStack = ['#b1b852','#3f8aa5','#c2d05b','#499EBF','#3fe362','#50add2','#d5e776',];

     scores.forEach((score) =>{
         let key = '';
         let category = score.transScoreCategoryName
         if(category === 'Legal-Reg-OtherRisks'){
             key = 'Lgl'
         }
         if(category === 'Industry'){
             key = 'Ind'
         }
         if(category === 'Management'){
             key = 'Mgt'
         }
         if(category === 'Optionality'){
             key = 'Opt'
         }
         if(category === 'Other'){
             key = 'Oth'
         }
         if(category === 'Thematic'){
             key = 'The'
         }
         if(category === 'Market-Product'){
             key = 'Mp'
         }

         if(scoreMap[score.transScoreCategoryName]){
             const count = scoreMap[key].count + 1;
             const total = scoreMap[key].total + score.score;
             const avgScore = total / count;
             scoreMap[key] = {
                 count: count,
                 total: total,
                 avgScore: avgScore,
             }
         }else{
             scoreMap[key] = {
                 count: 1,
                 total: score.score,
                 avgScore: score.score,
             }
         }
     });

    Object.keys(scoreMap).sort().forEach(key =>{
        orderedScoreMap[key] = scoreMap[key];
    })

    for(let category in orderedScoreMap){
        // let temp = ''
        // if(category === 'Legal-Reg-OtherRisks'){
        //     temp = 'Legal-Other'
        // }
        //labels.push(temp === '' ? category : temp)
        labels.push(category.substring(5,0))

        score.push(orderedScoreMap[category].avgScore)

        // if category score is less set the color to red
        if(Math.sign(orderedScoreMap[category].avgScore) < 0) {
            colors.push('#e57373')
        }else{
            colors.push(colorStack.pop())
        }
    }

    const data = {
        labels: labels,
        datasets: [
            {
                backgroundColor: colors,
                data: score,
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: true,
        legend: {
            display: false,
            position: 'top',
        },
        scales: {
            yAxes: [
                {
                    ticks: {
                        fontColor: '#fff',
                    },
                },
            ],
            xAxes: [
                {
                    ticks: {
                        display: true,
                        beginAtZero: true,
                        //steps: 10,
                        //stepValue: 5,
                        max:10
                    },
                },
            ],
        },
    };

    return transScoreData.loading ? (
        <div className={classes.loading}><CircularProgress /></div>
    ) : transScoreData.error ? (
        <div>{transScoreData.error}</div>
    ) :  (
        <Fragment>
            <div className={classes.circle}>
                <Typography variant="body1" align="center">
                    <span style={{fontSize: String(totalScore).length === 4 ? 28 : 32,
                                  fontWeight: "bold"}}>{totalScore}</span>
                </Typography>
            </div>
            <div id="chart-wrapper" className={classes.chart}>
                <HorizontalBar data={data} options={options} />
            </div>
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    transScoreData: state.research.transScoreData,
});

export default connect(mapStateToProps, {})(TransScoreChartContainer);