import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide/Slide';
import AddEditNote from './AddEditInvGenNote';
import {addGenNote, deleteGenNote, updateGenNote} from "../../../../../actions/researchActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import InvGenNoteCollapsibleTable from "./InvGenNoteCollapsibleTable";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        color: '#649cb5',
        width: '100%',
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
    },
}));

const InvGenNote = ({loading, error, genNotes, addGenNote, updateGenNote, deleteGenNote}) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');

    //const [isAddMode, setIsAddMode] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({ idkDD_Memo: 0 });
    const [showAddEdit, setShowAddEdit] = React.useState(false);

    const handleAddEditClicked = (event) => {
        if (event.currentTarget.parentNode.parentNode.dataset.id) {
            let id = event.currentTarget.parentNode.parentNode.dataset.id;
            //grab score to send to add/edit
            let theme = genNotes.filter(
                (note) => String(note.idkDD_stock) === id
            );

            setInitialValues(theme[0]);
            setShowAddEdit(true);
            //setIsAddMode(false);
        } else {
            setInitialValues({});
            setShowAddEdit(true);
            //setIsAddMode(true);
        }
    };

    function onSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (values.idkDD_stock) {
            updateInvGenNote(values, resetForm);
        } else {
            createInvGenNote(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteGenNote(values);
        setInitialValues({});
    }

    function createInvGenNote(values, resetForm) {
        addGenNote(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    function updateInvGenNote(values, resetForm) {
        updateGenNote(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    const handleCancel = () => {
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    };

    return loading ? (<div className={classes.loading}><CircularProgress /></div>) :
            error ? (<div>{error}</div>) : (
        <div>
            <div className={classes.title}>
                <Typography variant="h6">Note</Typography>
            </div>
            {!showAddEdit ? (
                <div>
                    <InvGenNoteCollapsibleTable
                        mobile={mobile}
                        genNotes={genNotes}
                        handleAddEditClicked={handleAddEditClicked}
                    />
                </div>
            ) : (
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div>
                        <AddEditNote
                            initialValues={initialValues}
                            setShowAddEdit={setShowAddEdit}
                            onSubmit={onSubmit}
                            handleDelete={handleDelete}
                            handleCancel={handleCancel}
                        />
                    </div>
                </Slide>
            )}
        </div>
    );
};

InvGenNote.propTypes = {};

const mapStateToProps = (state) => ({
    loading : state.research.genNoteData.loading,
    error: state.research.genNoteData.error,
    genNotes: state.research.genNoteData.genNotes,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addGenNote: (invGenNote) => dispatch(addGenNote(invGenNote)),
        updateGenNote: (invGenNote) => dispatch(updateGenNote(invGenNote)),
        deleteGenNote: (invGenNote) => dispatch(deleteGenNote(invGenNote))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvGenNote);
