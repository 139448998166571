import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Form, Formik } from 'formik';
import { connect } from 'react-redux';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import CircularProgress from "@material-ui/core/CircularProgress";
import Strategy from "./Strategy";
import {updatePassFail} from "../../../../actions/researchActions";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    loading:  {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 150,
    },
    gridRoot: {
        width: '100%',
        height: '100vh',
        marginTop: 5,
    },
    gridPaper: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        minHeight: 280,
    },
    selectGroup: {
        display: 'flex',
        padding: 4,
    },
    toolBarText: { flex: 1 },
}));

const PassFail = ({passFailData, optionValues, updatePassFail}) => {
    const classes = useStyles();
    const {idkInvestment, evoDescription, strategies } = { ...passFailData.stockDetails };

    function handleSubmit(values, { setStatus, setSubmitting }) {
        const invPassFail = checkForChanges(values, passFailData);
        updatePassFail(invPassFail)
        setSubmitting(false);
    }

    function syncStrategyDetails (details, setFieldValue) {
        for(let i = 0 ; i < strategies.length; i++){
            setFieldValue(`strategies.${i}.idkPassFail`,details.idkPassFail);
            setFieldValue(`strategies.${i}.pfRational`,details.pfRational);
        }
    }

    return passFailData.loading ? (
            <div className={classes.loading}><CircularProgress /></div>
        ) : passFailData.error ? (<div> {passFailData.error}</div>) :
        ( <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: idkInvestment,
                    evoDescription: evoDescription ? evoDescription : '',
                    strategies: strategies ? strategies : []
                }}
                onSubmit={handleSubmit}
        >
            {({ values, dirty, setFieldValue }) => (
                <Form className={classes.gridRoot}>
                    <div style={{display: "flex", alignItems: "center", height: 30}}>
                        <div style={{flex: 1}}></div>
                        {/*<Slide style={{display: "flex",*/}
                        {/*               justifyContent: 'center',*/}
                        {/*               flex: 1}}*/}
                        {/*                direction="down"*/}
                        {/*                timeout={1050}*/}
                        {/*                in={dirty} mountOnEnter unmountOnExit>*/}
                         <div style={{display: "flex", alignItems: "flex-start", flex: 1}}>
                            <IconButton disabled={dirty ? false : true} type="submit" aria-label="save">
                                <SaveIcon />
                            </IconButton>
                            <IconButton
                                //onClick={props.handleCancel}
                                disabled={dirty ? false : true}
                                type="reset"
                                aria-label="cancel"
                            >
                                <CancelIcon />
                            </IconButton>
                            </div>
                        {/*</Slide>*/}
                    </div>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.gridPaper}>
                                <Field
                                    style={{
                                        width: '100%',
                                        padding: '0px 10px 0px 0px',
                                        margin: '5px 0px 0px 5px',
                                    }}
                                    type="input"
                                    name="evoDescription"
                                    variant="outlined"
                                    label="Description"
                                    multiline
                                    rows={10}
                                    value={values.evoDescription}
                                    as={TextField}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.gridPaper}>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        border: '1px solid #6d6d6d',
                                        borderRadius: 3,
                                        padding: '0px 5px 0px 5px',
                                        minHeight: 250,
                                    }}
                                >
                                   <Strategy values={values} dirty={dirty}
                                             syncStrategyDetails={syncStrategyDetails}
                                             setFieldValue={setFieldValue}/>
                                </div>
                            </Paper>
                        </Grid>
                    </Grid>
                    {/*<pre style={{ fontSize: 'small' }}>*/}
                    {/*    {JSON.stringify(values, null, 2)}*/}
                    {/*</pre>*/}
                </Form>
            )}
        </Formik>
    );
};

PassFail.propTypes = {};

const mapStateToProps = (state) => ({
    passFailData: state.research.passFailData,
    optionValues: state.research.optionValues,
});


const mapDispatchToProps = (dispatch) => {
    return {
        updatePassFail: (invPassFail) => dispatch(updatePassFail(invPassFail))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(PassFail);

const checkForChanges = (values, passFailData) => {
    const updates = {'idkInvestment': 0, 'strategies': []}
    const orgDetails = passFailData.stockDetails;
    const trackedFields = new Set(['idkInvestment',
        'evoDescription',
        'idk_strategy_qualifier',
        'idkPassFail',
        'pfRational',])

    // check for changes to parent invest obj
    for (let key in orgDetails){
        if(trackedFields.has(key) && values[key] !== orgDetails[key]){
            updates['idkInvestment'] = orgDetails['idkInvestment']
            updates[key] = values[key]
        }
    }

    //check for changes to child strategy objs
    for(let s = 0; s <  orgDetails['strategies'].length; s++){
        let strategy = orgDetails['strategies'][s];
        updates['idkInvestment'] = orgDetails['idkInvestment']
        updates['strategies'].push({idk_strategy_qualifier : strategy.idk_strategy_qualifier})

        for (let key in strategy) {
            let stratValues = values['strategies'][s];
            if (trackedFields.has(key) && stratValues[key] !== strategy[key]) {
                let tempObj = updates['strategies'][s]
                tempObj[key] = stratValues[key];
                updates['strategies'][s] = tempObj;
            }
        }
    }
    return updates;
}
