import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';
import TextField from '@material-ui/core/TextField/TextField';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckIcon from '@material-ui/icons/Check';

import { makeStyles } from '@material-ui/core/styles';
//import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
    autoComplete: {
        width: '100%',
    },
    ddStatus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 5px 0 0',
    },
    ddCompulsoryNotStarted: {
        color: '#616161',
        fontSize: 'medium',
    },
    ddNotStarted: {
        color: '#616161',
        fontSize: 'medium',
    },
    ddInProgress: {
        color: '#fff176',
        fontSize: 'medium',
    },
    ddCompleted: {
        color: '#66bb6a',
        fontSize: 'medium',
    },
    ddOutOfDated: {
        color: '#ef5350',
        fontSize: 'medium',
    },
}));

DueDiligenceAutoComplete.propTypes = {};

function DueDiligenceAutoComplete(props) {
    const classes = useStyles();

    function addStatusToStep(compulsory, status) {
        if ((!status || status === 'NotStarted') && compulsory) {
            return (
                <span className={classes.ddStatus}>
                    <CheckCircleOutlineIcon className={classes.ddCompulsoryNotStarted} />
                </span>
            );
        }else if((!status || status === 'NotStarted') && !compulsory){
           return( <span className={classes.ddStatus}>
                    <CheckIcon className={classes.ddNotStarted} />
                </span>)
        }

        if (status === 'InProgress' && compulsory) {
            return (
                <span className={classes.ddStatus}>
                    <CheckCircleOutlineIcon className={classes.ddInProgress} />
                </span>
            );
        }else if(status === 'InProgress' && !compulsory){
            return( <span className={classes.ddStatus}>
                    <CheckIcon className={classes.ddInProgress} />
                </span>)
        }

        if (status === 'Completed' && compulsory) {
            return (
                <span className={classes.ddStatus}>
                    <CheckCircleIcon className={classes.ddCompleted} />
                </span>
            );
        }else if(status === 'Completed' && !compulsory){
            return( <span className={classes.ddStatus}>
                    <CheckIcon className={classes.ddCompleted} />
                </span>)
        }

        if (status === 'OutOfDate' && compulsory) {
            return (
                <span className={classes.ddStatus}>
                    <CheckCircleIcon className={classes.ddOutOfDated} />
                </span>
            );
        }
    }

    return (
        <Autocomplete
            id="ddAutoComplete"
            name="ddAutoComplete"
            options={props.dueDiligenceSteps ? props.dueDiligenceSteps : []} //array of steps for auto complete
            getOptionLabel={(option) => option.Action_Name}
            className={classes.autoComplete}
            onChange={(e, value) => {
                props.handleAutoCompleteAction(
                    value !== null ? value.idkDD_Action : props.ddAction.idkDD_Action
                );
            }}
            getOptionSelected={((option, value) => option.idkDD_Action === value.idkDD_Action)}
            renderOption={(option) => (
                <React.Fragment>
                    <span>{addStatusToStep(option.compulsory, option.actionStatus)}</span>
                    {option.Action_Name}
                </React.Fragment>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    margin="none"
                    label="Choose Due Diligence Step"
                    fullWidth
                    variant="outlined"
                    name="ddAutoCompleteTextField"
                />
            )}
        />
    );
}

export default DueDiligenceAutoComplete;
