import React from 'react';
import { connect } from 'react-redux';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from "yup";
import {makeStyles} from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import TD3FormikSelectField from '../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField';
import InputAdornment from "@material-ui/core/InputAdornment";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import StarRateIcon from '@material-ui/icons/StarRate';
import AddEditToolBar from "../../common/AddEditToolBar";
import TokenService from "../../../../services/token-service";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
        //marginTop: 5
    },
    numberField: {
        flex: 1,
        margin: 1,
        '& .MuiInputBase-input::-webkit-outer-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input::-webkit-inner-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input[type=number]' : {'-moz-appearance': 'textfield'},
    }
}));

const AddEditDueDiligence = (props) => {
    const classes = useStyles();
    const {
        idkDD_stock,
        idkDD_Action,
        enteredby,
        editedby,
        DD_Status,
        DD_Value,
        DD_Conviction,
        DD_Rank,
        idkCyclicalIndustry,
        DD_HyperLink,
        idkDD_Memo,
        Memo
    } = {
        ...props.initialValues,
    };


    const percent = <Typography variant='body2'>%</Typography>
    const dollor = <Typography variant='body2'>$</Typography>
    const startAdornmentMap = {25: <StarRateIcon style={{fontSize: 'medium'}}/>,16:dollor,9:'#',10:'#',14:'#',15:'#',22:'#',23:'#',24:'#'}
    const endAdornmentMap = {13:percent,26:percent,53:percent,54:percent,}

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idkDD_stock: values.idkDD_stock,
                idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
    };

    const validationSchema = Yup.object({
        DD_Conviction: Yup.number().moreThan(-1,'Must be between 0 and 10' )
                                                .lessThan(11,'Must be between 0 and 10' ).required('Required'),
        DD_Rank: Yup.number().moreThan(-1,'Must be between 0 and 10' )
                                         .lessThan(11,'Must be between 0 and 10' ).required('Required'),
        DD_Value: Yup.number().when('idkDD_Action', {
                                is: 15, then: Yup.number().min(-1, 'Must be -1 (Decreasing), 0 (Holding steady), or 1 (Increasing)')
                                                                    .max(1, 'Must be -1 (Decreasing), 0 (Holding steady),or  1 (Increasing)'),
                            }).when('idkDD_Action', {
                                is: 14, then: Yup.number().min(1, 'Must be 1 thru 5, with 1 being #1 in market position.')
                                                                    .max(5, 'Must be 1 thru 5, with 1 being #1 in market position.'),
                            }).when('idkDD_Action', {
                                is: 25 , then: Yup.number().min(1, 'Must be 1 thru 5 for star rating.')
                                                                    .max(5, 'Must be 1 thru 5 for star rating.'),
                            }).when('idkDD_Action', {
                                is: 9 , then: Yup.number().min(1, 'Must be 1 or greater'),
                            }).when('idkDD_Action', {
                                is: 10 , then: Yup.number().min(1, 'Must be 1 or greater'),
                            }).when('idkDD_Action', {
                                is: 22 , then: Yup.number().min(1, 'Must be 1 or greater'),
                            }).when('idkDD_Action', {
                                is: 23 , then: Yup.number().min(1, 'Must be 1 or greater'),
                            }).when('idkDD_Action', {
                                is: 55 , then: Yup.number().min(.01, 'Must be percent. ex: 33% = .33')
                                                                    .max(.99, 'Must be percent. ex: 33% = .33'),
                            }).when('idkDD_Action', {
                                is: 54 , then: Yup.number().min(.01, 'Must be percent. ex: 33% = .33')
                                                                    .max(.99, 'Must be percent. ex: 33% = .33'),
                            }).when('idkDD_Action', {
                                is: 53 , then: Yup.number().min(.01, 'Must be percent. ex: 33% = .33')
                                                                    .max(.99, 'Must be percent. ex: 33% = .33'),
                            }).when('idkDD_Action', {
                                is: 26 , then: Yup.number().min(.01, 'Must be percent. ex: 33% = .33')
                                                                    .max(.99, 'Must be percent. ex: 33% = .33'),
                            }).when('idkDD_Action', {
                                is: 13 , then: Yup.number().min(.01, 'Must be percent. ex: 33% = .33')
                                                                    .max(.99, 'Must be percent. ex: 33% = .33'),
                            })
    });

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: props.currentInvestment,
                    idkDD_stock: idkDD_stock ? idkDD_stock : '',
                    idkDD_Action: idkDD_Action ? idkDD_Action : '',
                    DD_Status: DD_Status ? DD_Status : 'InProgress',
                    DD_Value: DD_Value ? DD_Value : '',
                    DD_Conviction: DD_Conviction ? DD_Conviction : 5,
                    DD_Rank: DD_Rank ? DD_Rank : 5,
                    idkCyclicalIndustry : idkCyclicalIndustry ? idkCyclicalIndustry : '',
                    DD_HyperLink: DD_HyperLink ? DD_HyperLink : '',
                    idkDD_Memo: idkDD_Memo ? idkDD_Memo : '',
                    Memo: Memo ? Memo : '',
                    enteredby: enteredby ? enteredby : TokenService.getCurrentUser(),
                    editedby: editedby ? editedby : TokenService.getCurrentUser(),
                }}
                validationSchema={validationSchema}
                onSubmit={props.onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form>
                        <AddEditToolBar
                            mobile={props.mobile}
                            combined={props.mobile}
                            handleCancel={props.handleCancel}
                            handleDelete={handleDeleteConfirmed}
                            saveDisabled={!dirty}
                            cancelDisabled={!dirty}
                            deleteDisabled={!values.idkDD_stock}
                            setShowAddEdit={props.setShowAddEdit}
                            values={values}
                        />

                        <div className={classes.selectGroup}>
                            <Field
                                style={{ flex: 1 }}
                                type="input"
                                name="enteredby"
                                label="Analyst"
                                variant="outlined"
                                disabled
                                value={values.enteredby}
                                as={TextField}
                            />
                            <TD3FormikSelectField
                                name="DD_Status"
                                label="Status"
                                margin="normal"
                                style={{ flex: 1, margin: 1 }}
                                variant="outlined"
                                options={[
                                    { label: '', value: '' },
                                    { label: 'Completed', value: 'Completed' },
                                    {
                                        label: 'In-Progress',
                                        value: 'InProgress',
                                    },
                                ]}
                            />
                        </div>
                        <div className={classes.selectGroup}>
                            {startAdornmentMap.hasOwnProperty(values.idkDD_Action) ?
                                <Field
                                    className={classes.numberField}
                                    type="number"
                                    name="DD_Value"
                                    label="Value"
                                    variant="outlined"
                                    value={values.DD_Value}
                                    error={errors.DD_Value}
                                    helperText={<ErrorMessage name='DD_Value'/>}
                                    as={TextField}
                                    placeholder={values.idkDD_Action === 16 ? 'millions' : ''}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                { startAdornmentMap[values.idkDD_Action] }
                                            </InputAdornment>
                                    }}
                                /> :
                                endAdornmentMap.hasOwnProperty(values.idkDD_Action) ?
                                <Field
                                    className={classes.numberField}
                                    type="number"
                                    name="DD_Value"
                                    label="Value"
                                    variant="outlined"
                                    value={values.DD_Value}
                                    error={errors.DD_Value}
                                    helperText={<ErrorMessage name='DD_Value'/>}
                                    as={TextField}
                                    placeholder={values.idkDD_Action === 16 ? 'millions' : ''}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                        { endAdornmentMap[values.idkDD_Action] }
                                            </InputAdornment>
                                    }}
                                /> :
                                    <Field
                                        disabled={true}
                                        className={classes.numberField}
                                        type="number"
                                        name="DD_Value"
                                        label="Value"
                                        variant="outlined"
                                        value={values.DD_Value}
                                        error={errors.DD_Value}
                                        helperText={<ErrorMessage name='DD_Value'/>}
                                        as={TextField}
                                        placeholder={values.idkDD_Action === 16 ? 'millions' : ''}
                                        InputProps={{
                                            startAdornment:
                                                <InputAdornment position="start">
                                                  <NotInterestedIcon/>
                                                </InputAdornment>
                                        }}
                                    />
                            }
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Conviction"
                                label="Conviction"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Conviction'/>}
                                as={TextField}
                            />
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Rank"
                                label="Rank"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Rank'/>}
                                as={TextField}
                            />
                        </div>
                        <div className={classes.selectGroup}>
                            <Field
                                style={{ flex: 1, margin: 1 }}
                                type="input"
                                name="DD_HyperLink"
                                label="Link"
                                variant="outlined"
                                value={values.DD_HyperLink}
                                as={TextField}
                            />
                        </div>
                        {idkDD_Action === 17 || idkDD_Action === 18 ?
                            <div className={classes.selectGroup}>
                            <TD3FormikSelectField
                                name="idkCyclicalIndustry"
                                label={idkDD_Action === 17 ? 'Industry' : 'Cyclical Quadrant'}
                                margin="normal"
                                style={{ flex: 1, margin: 1 , width: '100%'}}
                                variant="outlined"
                                options={idkDD_Action === 17 ?
                                    props.optionValues.industryOptions.map(opt =>{
                                        return {label: opt.evo_industry_name,
                                            value: opt.idk_evo_industry}
                                    }) : props.optionValues.cyclicalOptions.map(opt =>{
                                        return {label: opt.cyclicalQuadrantName,
                                            value: opt.idkCyclicalQuadrant}
                                    })}

                            /> </div>: null}
                        <div style={{ padding: 5 }}>
                            <Field
                                style={{ width: '100%' }}
                                type="input"
                                name="Memo"
                                variant="outlined"
                                label="Note"
                                multiline
                                rows={6}
                                value={values.Memo}
                                as={TextField}
                            />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 4)}</pre>*/}
                    </Form>
                )}
            </Formik>
        </div>
    );
};

AddEditDueDiligence.propTypes = {};

const mapStateToProps = (state) => ({
    currentInvestment: state.research.currentInvestment,
    optionValues: state.research.optionValues,
});

export default connect(mapStateToProps, {})(AddEditDueDiligence);
