import {
    FETCH_UNIVERSE_REQUEST,
    FETCH_UNIVERSE_SUCCESS,
    FETCH_UNIVERSE_ERROR,
    REHYDRATE_UNIVERSE,

    SET_CURRENT_INVESTMENT,
    SET_CURRENT_INVESTMENT_SUCCESS,
    SET_CURRENT_INVESTMENT_ERROR,

    FETCH_PASSFAIL_REQUEST,
    FETCH_PASSFAIL_SUCCESS,
    FETCH_PASSFAIL_ERROR,
    UPDATE_PASSFAIL_REQUEST,
    UPDATE_PASSFAIL_SUCCESS,
    UPDATE_PASSFAIL_ERROR,

    TRANSSCORE_REQUEST_FETCH,
    TRANSSCORE_REQUEST_ADD,
    TRANSSCORE_REQUEST_UPDATE,
    TRANSSCORE_REQUEST_DELETE,
    TRANSSCORE_SUCCESS,
    TRANSSCORE_ERROR,

    DUE_DILIGENCE_REQUEST_FETCH,
    DUE_DILIGENCE_REQUEST_ADD,
    DUE_DILIGENCE_REQUEST_UPDATE,
    DUE_DILIGENCE_REQUEST_DELETE,
    DUE_DILIGENCE_SUCCESS,
    DUE_DILIGENCE_ERROR,

    BIGMOVE_REQUEST_FETCH,
    BIGMOVE_REQUEST_ADD,
    BIGMOVE_REQUEST_UPDATE,
    BIGMOVE_REQUEST_DELETE,
    BIGMOVE_SUCCESS,
    BIGMOVE_ERROR,

    GENNOTE_REQUEST_FETCH,
    GENNOTE_REQUEST_ADD,
    GENNOTE_REQUEST_UPDATE,
    GENNOTE_REQUEST_DELETE,
    GENNOTE_SUCCESS,
    GENNOTE_ERROR,

    THEMATIC_REQUEST_FETCH,
    THEMATIC_REQUEST_ADD,
    THEMATIC_REQUEST_UPDATE,
    THEMATIC_REQUEST_DELETE,
    THEMATIC_SUCCESS,
    THEMATIC_ERROR,

    COMPETITOR_REQUEST_FETCH,
    COMPETITOR_REQUEST_ADD,
    COMPETITOR_REQUEST_UPDATE,
    COMPETITOR_REQUEST_DELETE,
    COMPETITOR_SUCCESS,
    COMPETITOR_ERROR,

    FETCH_OPTION_VALUES_REQUEST,
    FETCH_OPTION_VALUES_SUCCESS,
    FETCH_OPTION_VALUES_ERROR,

} from '../actions/types';
import ResearchApiService from '../services/research-api-service';

////////////////////////////////////////////////////////
// CURRENT INVESTMENT ACTIONS
////////////////////////////////////////////////////////
export const setCurrentInvestment = (currentInvestment) =>{
    return (dispatch)=>{
        dispatch(setCurrentInvestmentAction())
        try{
            dispatch(setCurrentInvestmentSuccessAction(currentInvestment))
        }
        catch (err) {
            setCurrentInvestmentSuccessError(err)
        }
    }
};
const setCurrentInvestmentAction = ()  => {
    return {
        type: SET_CURRENT_INVESTMENT,
    };
};
const setCurrentInvestmentSuccessAction = (currentInvestment)  => {
    return {
        type: SET_CURRENT_INVESTMENT_SUCCESS,
        payload: currentInvestment
    };
};
const setCurrentInvestmentSuccessError = (error)  => {
    return {
        type: SET_CURRENT_INVESTMENT_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// UNIVERSE ACTIONS
////////////////////////////////////////////////////////
export const fetchUniverse = () => {
    return (dispatch) => {
        dispatch(fetchUniverseRequestAction())
        ResearchApiService.getUniverse()
            .then(res =>{
                const universeData = [];
                for(let key in res){
                    universeData.push(res[key])
                }
                window.sessionStorage.setItem('universeData', JSON.stringify(universeData));
                dispatch(fetchUniverseSuccessAction(universeData))
            }).catch(err =>{
            dispatch(fetchUniverseErrorAction(err))
        })
    }
};
export const rehydrateUniverse = () =>{
    return (dispatch)=>{
        dispatch(rehydrateUniverseAction())
        try{
            const universeData = JSON.parse(window.sessionStorage.getItem('universeData'));
            dispatch(fetchUniverseSuccessAction(universeData));
        }
        catch (err) {
            dispatch(fetchUniverseErrorAction(err))
        }
    }
};
const rehydrateUniverseAction = () =>{
    return {
        type: REHYDRATE_UNIVERSE,
    };
};
const fetchUniverseRequestAction = ()  => {
    return {
        type: FETCH_UNIVERSE_REQUEST,
    };
};
const fetchUniverseSuccessAction = (universeData)  => {
    return {
        type: FETCH_UNIVERSE_SUCCESS,
        payload: universeData
    };
};
const fetchUniverseErrorAction = (error)  => {
    return {
        type: FETCH_UNIVERSE_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// OPTION VALUES ACTIONS
////////////////////////////////////////////////////////
export const fetchOptionValues = () => {
    return (dispatch)=>{
        dispatch(fetchOptionValuesRequestAction())
        ResearchApiService.getOptionValues()
            .then(res =>{
                const pfs = res[0];
                const themes = res[1];
                const inds = res[2];
                const cycls = res[3];
                const cats = res[4];

                const options = {
                    passFailOptions : pfs,
                    themeOptions: themes,
                    industryOptions: inds,
                    cyclicalOptions: cycls,
                    categoryOptions: cats,
                };
                dispatch(fetchOptionValuesSuccessAction(options))
            }).catch(err =>{
            dispatch(fetchOptionValuesErrorAction(err))
        })
    }
};
const fetchOptionValuesRequestAction = ()  => {
    return {
        type: FETCH_OPTION_VALUES_REQUEST,
    };
};
const fetchOptionValuesSuccessAction = (optionsValues)  => {
    return {
        type: FETCH_OPTION_VALUES_SUCCESS,
        payload: optionsValues
    };
};
const fetchOptionValuesErrorAction = (error)  => {
    return {
        type: FETCH_OPTION_VALUES_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// PASS FAIL ACTIONS
////////////////////////////////////////////////////////
export const fetchPassFail = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchPassFailRequestAction())
        ResearchApiService.getInvPassFail(idkInvestment)
            .then(res =>{
                const passFail = res;
                dispatch(fetchPassFailSuccessAction(passFail[0]))
            }).catch(err =>{
                dispatch(fetchPassFailErrorAction(err.message))
        })
    }
};
export const updatePassFail = (invPassFailUpdates) => {
    return (dispatch)=>{
        dispatch(updatePassFailRequestAction())
        ResearchApiService.updateInvPassFail(invPassFailUpdates)
            .then(res =>{
                const updatedPassFail = res;
                dispatch(updatePassFailSuccessAction(updatedPassFail[0]))
            }).catch(err =>{
                dispatch(updatePassFailErrorAction(err.message))
        })
    }
};
const fetchPassFailRequestAction = ()  => {
    return {
        type: FETCH_PASSFAIL_REQUEST,
    };
};
const fetchPassFailSuccessAction = (stock)  => {
    return {
        type: FETCH_PASSFAIL_SUCCESS,
        payload: stock
    };
};
const fetchPassFailErrorAction = (error)  => {
    return {
        type: FETCH_PASSFAIL_ERROR,
        payload: error
    };
};
const updatePassFailRequestAction = ()  => {
    return {
        type: UPDATE_PASSFAIL_REQUEST,
    };
};
const updatePassFailSuccessAction = (updatedPassFail)  => {
    return {
        type: UPDATE_PASSFAIL_SUCCESS,
        payload: updatedPassFail
    };
};
const updatePassFailErrorAction = (error)  => {
    return {
        type: UPDATE_PASSFAIL_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// TRANS SCORE ACTIONS
////////////////////////////////////////////////////////
export const fetchTransScore = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchTransScoreRequestAction())
        ResearchApiService.getInvTransScore(idkInvestment)
            .then(res =>{
                dispatch(transScoreSuccessAction(res))
            }).catch(err =>{
            dispatch(transScoreErrorAction(err.message))
        })
    }
};
export const addTransScore = (invTransScore) => {
    return (dispatch)=>{
        dispatch(addTransScoreRequestAction())
        ResearchApiService.addInvTransScore(invTransScore)
            .then(res =>{
                dispatch(transScoreSuccessAction(res))
            }).catch(err =>{
                dispatch(transScoreErrorAction(err.message))
        })
    }
};
export const updateTransScore = (invTransScore) => {
    return (dispatch)=>{
        dispatch(updateTransScoreRequestAction())
        ResearchApiService.updateInvTransScore(invTransScore)
            .then(res =>{
                dispatch(transScoreSuccessAction(res))
            }).catch(err =>{
                dispatch(transScoreErrorAction(err.message))
        })
    }
};
export const deleteTransScore = (invTransScore) => {
    return (dispatch)=>{
        console.log(invTransScore)
        dispatch(deleteTransScoreRequestAction())
        ResearchApiService.deleteInvTransScore(invTransScore)
            .then(res =>{
                dispatch(transScoreSuccessAction(res))
            }).catch(err =>{
                dispatch(transScoreErrorAction(err.message))
        })
    }
};
const fetchTransScoreRequestAction = ()  => {
    return {
        type: TRANSSCORE_REQUEST_FETCH,
    };
};
const addTransScoreRequestAction = ()  => {
    return {
        type: TRANSSCORE_REQUEST_ADD,
    };
};
const updateTransScoreRequestAction = ()  => {
    return {
        type: TRANSSCORE_REQUEST_UPDATE,
    };
};
const deleteTransScoreRequestAction = ()  => {
    return {
        type: TRANSSCORE_REQUEST_DELETE,
    };
};
const transScoreSuccessAction = (transScore)  => {
    return {
        type: TRANSSCORE_SUCCESS,
        payload: transScore
    };
};
const transScoreErrorAction = (error)  => {
    return {
        type: TRANSSCORE_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// BIG MOVE ACTIONS
////////////////////////////////////////////////////////
export const fetchBigMove = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchBigMoveRequestAction())
        ResearchApiService.getInvBigMove(idkInvestment)
            .then(res =>{
                dispatch(bigMoveSuccessAction(res))
            }).catch(err =>{
            dispatch(bigMoveErrorAction(err.message))
        })
    }
};
export const addBigMove = (invBigMove) => {
    return (dispatch)=>{
        dispatch(addBigMoveRequestAction())
        ResearchApiService.addInvBigMove(invBigMove)
            .then(res =>{
                dispatch(bigMoveSuccessAction(res))
            }).catch(err =>{
                dispatch(bigMoveErrorAction(err.message))
        })
    }
};
export const updateBigMove = (invBigMove) => {
    return (dispatch)=>{
        dispatch(updateBigMoveRequestAction())
        ResearchApiService.updateInvBigMove(invBigMove)
            .then(res =>{
                dispatch(bigMoveSuccessAction(res))
            }).catch(err =>{
                dispatch(bigMoveErrorAction(err.message))
        })
    }
};
export const deleteBigMove = (invBigMove) => {
    return (dispatch)=>{
        dispatch(deleteBigMoveRequestAction())
        ResearchApiService.deleteInvBigMove(invBigMove)
            .then(res =>{
                dispatch(bigMoveSuccessAction(res))
            }).catch(err =>{
                dispatch(bigMoveErrorAction(err.message))
        })
    }
};
const fetchBigMoveRequestAction = ()  => {
    return {
        type: BIGMOVE_REQUEST_FETCH,
    };
};
const addBigMoveRequestAction = ()  => {
    return {
        type: BIGMOVE_REQUEST_ADD,
    };
};
const updateBigMoveRequestAction = ()  => {
    return {
        type: BIGMOVE_REQUEST_UPDATE,
    };
};
const deleteBigMoveRequestAction = ()  => {
    return {
        type: BIGMOVE_REQUEST_DELETE,
    };
};
const bigMoveSuccessAction = (invBigMove)  => {
    return {
        type: BIGMOVE_SUCCESS,
        payload: invBigMove
    };
};
const bigMoveErrorAction = (error)  => {
    return {
        type: BIGMOVE_ERROR,
        payload: error
    };
};

////////////////////////////////////////////////////////
// GEN NOTE ACTIONS
////////////////////////////////////////////////////////
export const fetchGenNote = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchGenNoteRequestAction())
        ResearchApiService.getInvGenNote(idkInvestment)
            .then(res =>{
                dispatch(genNoteSuccessAction(res))
            }).catch(err =>{
            dispatch(genNoteErrorAction(err.message))
        })
    }
};
export const addGenNote = (invGenNote) => {
    return (dispatch)=>{
        dispatch(addGenNoteRequestAction())
        ResearchApiService.addInvGenNote(invGenNote)
            .then(res =>{
                dispatch(genNoteSuccessAction(res))
            }).catch(err =>{
                dispatch(genNoteErrorAction(err.message))
        })
    }
};
export const updateGenNote = (invGenNote) => {
    return (dispatch)=>{
        dispatch(updateGenNoteRequestAction())
        ResearchApiService.updateInvGenNote(invGenNote)
            .then(res =>{
                dispatch(genNoteSuccessAction(res))
            }).catch(err =>{
                dispatch(genNoteErrorAction(err.message))
        })
    }
};
export const deleteGenNote = (invGenNote) => {
    return (dispatch)=>{
        dispatch(deleteGenNoteRequestAction())
        ResearchApiService.deleteInvGenNote(invGenNote)
            .then(res =>{
                dispatch(genNoteSuccessAction(res))
            }).catch(err =>{
                dispatch(genNoteErrorAction(err.message))
        })
    }
};
const fetchGenNoteRequestAction = ()  => {
    return {
        type: GENNOTE_REQUEST_FETCH,
    };
};
const addGenNoteRequestAction = ()  => {
    return {
        type: GENNOTE_REQUEST_ADD,
    };
};
const updateGenNoteRequestAction = ()  => {
    return {
        type: GENNOTE_REQUEST_UPDATE,
    };
};
const deleteGenNoteRequestAction = ()  => {
    return {
        type: GENNOTE_REQUEST_DELETE,
    };
};
const genNoteSuccessAction = (invGenNote)  => {
    return {
        type: GENNOTE_SUCCESS,
        payload: invGenNote
    };
};
const genNoteErrorAction = (error)  => {
    return {
        type: GENNOTE_ERROR,
        payload: error
    };
};

////////////////////////////////////////////////////////
// THEMATIC ACTIONS
////////////////////////////////////////////////////////
export const fetchThematic = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchThematicRequestAction())
        ResearchApiService.getInvThematic(idkInvestment)
            .then(res =>{
                dispatch(thematicSuccessAction(res))
            }).catch(err =>{
            dispatch(thematicErrorAction(err.message))
        })
    }
};
export const addThematic = (theme) => {
    return (dispatch)=>{
        dispatch(addThematicRequestAction())
        ResearchApiService.addInvThematic(theme)
            .then(res =>{
                dispatch(thematicSuccessAction(res))
            }).catch(err =>{
            dispatch(thematicErrorAction(err.message))
        })
    }
};
export const updateThematic = (theme) => {
    return (dispatch)=>{
        dispatch(updateThematicRequestAction())
        ResearchApiService.updateInvThematic(theme)
            .then(res =>{
                dispatch(thematicSuccessAction(res))
            }).catch(err =>{
                dispatch(thematicErrorAction(err.message))
        })
    }
};
export const deleteThematic = (theme) => {
    return (dispatch)=>{
        dispatch(deleteThematicRequestAction())
        ResearchApiService.deleteInvThematic(theme)
            .then(res =>{
                dispatch(thematicSuccessAction(res))
            }).catch(err =>{
                dispatch(thematicErrorAction(err.message))
        })
    }
};

const fetchThematicRequestAction = ()  => {
    return {
        type: THEMATIC_REQUEST_FETCH,
    };
};
const addThematicRequestAction = ()  => {
    return {
        type: THEMATIC_REQUEST_ADD,
    };
};
const updateThematicRequestAction = ()  => {
    return {
        type: THEMATIC_REQUEST_UPDATE,
    };
};
const deleteThematicRequestAction = ()  => {
    return {
        type: THEMATIC_REQUEST_DELETE,
    };
};
const thematicSuccessAction = (themeList)  => {
    return {
        type: THEMATIC_SUCCESS,
        payload: themeList
    };
};
const thematicErrorAction = (error)  => {
    return {
        type: THEMATIC_ERROR,
        payload: error
    };
};
////////////////////////////////////////////////////////
// COMPETITOR ACTIONS
////////////////////////////////////////////////////////
export const fetchCompetitor = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchCompetitorRequestAction())
        ResearchApiService.getInvCompetitor(idkInvestment)
            .then(res =>{
                dispatch(competitorSuccessAction(res))
            }).catch(err =>{
            dispatch(competitorErrorAction(err.message))
        })
    }
};
export const addCompetitor = (invCompetitor) => {
    return (dispatch)=>{
        dispatch(addCompetitorRequestAction())
        ResearchApiService.addInvCompetitor(invCompetitor)
            .then(res =>{
                dispatch(competitorSuccessAction(res))
            }).catch(err =>{
            dispatch(competitorErrorAction(err.message))
        })
    }
};
export const updateCompetitor = (invCompetitor) => {
    return (dispatch)=>{
        dispatch(updateCompetitorRequestAction())
        ResearchApiService.updateInvCompetitor(invCompetitor)
            .then(res =>{
                dispatch(competitorSuccessAction(res))
            }).catch(err =>{
            dispatch(competitorErrorAction(err.message))
        })
    }
};
export const deleteCompetitor = (invCompetitor) => {
    console.log(invCompetitor)
    return (dispatch)=>{
        dispatch(deleteCompetitorRequestAction())
        ResearchApiService.deleteInvCompetitor(invCompetitor)
            .then(res =>{
                dispatch(competitorSuccessAction(res))
            }).catch(err =>{
            dispatch(competitorErrorAction(err.message))
        })
    }
};
const fetchCompetitorRequestAction = ()  => {
    return {
        type: COMPETITOR_REQUEST_FETCH,
    };
};
const addCompetitorRequestAction = ()  => {
    return {
        type: COMPETITOR_REQUEST_ADD,
    };
};
const updateCompetitorRequestAction = ()  => {
    return {
        type: COMPETITOR_REQUEST_UPDATE,
    };
};
const deleteCompetitorRequestAction = ()  => {
    return {
        type: COMPETITOR_REQUEST_DELETE,
    };
};
const competitorSuccessAction = (competitors)  => {
    return {
        type: COMPETITOR_SUCCESS,
        payload: competitors
    };
};
const competitorErrorAction = (error)  => {
    return {
        type: COMPETITOR_ERROR,
        payload: error
    };
};

////////////////////////////////////////////////////////
// Due Diligence ACTIONS
////////////////////////////////////////////////////////
export const fetchDueDiligence = (idkInvestment) => {
    return (dispatch)=>{
        dispatch(fetchDueDiligenceRequestAction())
        ResearchApiService.getInvDueDiligence(idkInvestment)
            .then(dueDiligence =>{
                dispatch(dueDiligenceSuccessAction(dueDiligence))
            }).catch(err =>{
            dispatch(dueDiligenceErrorAction(err.message))
        })
    }
};
export const addDueDiligence = (newDDRecord) => {
    return (dispatch)=>{
        dispatch(addDueDiligenceRequestAction())
        ResearchApiService.addInvDueDiligence(newDDRecord)
            .then(dueDiligence =>{
                dispatch(dueDiligenceSuccessAction(dueDiligence))
            }).catch(err =>{
            dispatch(dueDiligenceErrorAction(err.message))
        })
    }
};
export const updateDueDiligence = (updatedDDRecord) => {
    return (dispatch)=>{
        dispatch(updateDueDiligenceRequestAction())
        ResearchApiService.updateInvDueDiligence(updatedDDRecord)
            .then(dueDiligence =>{
                dispatch(dueDiligenceSuccessAction(dueDiligence))
            }).catch(err =>{
            dispatch(dueDiligenceErrorAction(err.message))
        })
    }
};
export const deleteDueDiligence = (deleteDDRecord) => {
    return (dispatch)=>{
        dispatch(deleteDueDiligenceRequestAction())
        ResearchApiService.deleteInvDueDiligence(deleteDDRecord)
            .then(dueDiligence =>{
                dispatch(dueDiligenceSuccessAction(dueDiligence))
            }).catch(err =>{
            dispatch(dueDiligenceErrorAction(err.message))
        })
    }
};
const fetchDueDiligenceRequestAction = ()  => {
    return {
        type: DUE_DILIGENCE_REQUEST_FETCH,
    };
};
const addDueDiligenceRequestAction = ()  => {
    return {
        type: DUE_DILIGENCE_REQUEST_ADD,
    };
};
const updateDueDiligenceRequestAction = ()  => {
    return {
        type: DUE_DILIGENCE_REQUEST_UPDATE,
    };
};
const deleteDueDiligenceRequestAction = ()  => {
    return {
        type: DUE_DILIGENCE_REQUEST_DELETE,
    };
};
const dueDiligenceSuccessAction = (dueDiligence)  => {
    return {
        type: DUE_DILIGENCE_SUCCESS,
        payload: dueDiligence
    };
};
const dueDiligenceErrorAction = (error)  => {
    return {
        type: DUE_DILIGENCE_ERROR,
        payload: error
    };
};

