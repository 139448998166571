import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import {format} from "date-fns";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row, handleAddEditClicked } = props;
    const [open, setOpen] = React.useState(false);
    const [down, setDown] = React.useState(String(row.DD_Value).charAt(0) === '-');
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow data-id={row.idkDD_stock} hover={true} className={classes.root}>
                    <TableCell padding='none'>
                        <IconButton   aria-label="expand row" size="medium" >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell onClick={() => setOpen(!open)} >{format(new Date(row.BM_Date), 'yyyy-MM-dd')}</TableCell>
                    <TableCell onClick={() => setOpen(!open)} >
                        <div style={{color: down ? '#e57373' : '#66bb6a', display: "flex", alignItems: 'center', flex: '1'}}>
                            {/*{down ? <RemoveIcon className={classes.smallIcon}/> : <AddIcon className={classes.smallIcon}/>}*/}
                            <Typography variant="body1" >
                                {down ? String(row.DD_Value).substring(1) : row.DD_Value}
                            </Typography>
                            {down ? <ArrowDropDownIcon className={classes.smallIconDown}/> :
                                <ArrowDropUpIcon className={classes.smallIconDown}/>}
                        </div>

                    </TableCell>
                <TableCell style={{textAlign: "end"}} padding='none' >
                    <IconButton style={{marginRight: 5}} aria-label="edit" onClick={handleAddEditClicked} >
                        <EditIcon  style={{fontSize: 'medium'}}/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2}>
                            <Typography style={{paddingLeft: 30, paddingRight: 30}} variant='body2'>
                                {row.Memo}
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {

};

export default function BigMoveCollapsibleTable(props) {
    return (
        <Fragment>
        <TableContainer component={Paper}>
            <Table stickyHeader size={props.mobile ? "small" : "medium"} aria-label="big move table">
                <TableHead >
                    <TableRow style={{borderRadius: 0}}>
                        <TableCell style={{border: "none"}}/>
                        <TableCell style={{border: "none"}}>Date</TableCell>
                        <TableCell style={{border: "none"}}>Value</TableCell>
                        <TableCell style={{border: "none", textAlign: "end"}} padding='none'>
                            <IconButton
                                    style={{fontSize: "medium"}}
                                    disabled={props.showAddEdit}
                                    onClick={props.handleAddEditClicked}
                                    aria-label="add"
                                >
                                    <AddIcon />
                                </IconButton>
                        </TableCell>
                          </TableRow>
                </TableHead>
                {props.bigMoves.length > 0 ? <TableBody>
                    {props.bigMoves.map((row) => (
                        <Row key={row.idkDD_stock}
                             row={row}
                             handleAddEditClicked={props.handleAddEditClicked}/>
                    ))}
                </TableBody> : null
                }
            </Table>
        </TableContainer>
        {!props.bigMoves.length > 0 ?
            <Typography variant={"body1"}
                        style={{padding: 10, textAlign: "center"}}>
                No Big Move Data
            </Typography> : null
        }
    </Fragment>
    );
}
