import React, { useEffect } from 'react';
import PrimarySearchAppBar from '../AppBar/PrimarySearchAppBar';
import { Typography } from '@material-ui/core';
import InvestmentUniverseGrid from './InvestmentUniverseGrid';
import { connect } from 'react-redux';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {makeStyles} from "@material-ui/core/styles";
import {fetchUniverse,} from '../../actions/researchActions';

const useStyles = makeStyles((theme) => ({
    name: {
        flex: 1,
        height: '100%',
        textAlign: 'left',
    },
    agGridContainer :{
        display: 'flex',
        padding: '15px 0 0 0',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        width: '100%',
    },
}));

function InvestmentUniverse({loading,
                                            error,
                                            universe,
                                            fetchUniverse, }) {
    const classes = useStyles();

    useEffect(() => {
        fetchUniverse();
    }, [fetchUniverse]);

    return loading ? (
        <div className={classes.agGridContainer}><CircularProgress/></div>
    ) : error ? (
        <div className={classes.agGridContainer}>{error}</div>
    ) : (
        <div>
            <PrimarySearchAppBar placeHolder={'Security'} />
            <div className={classes.agGridContainer}>
                <Typography variant="h4">Investment Universe</Typography>
                <InvestmentUniverseGrid rowData={universe.filter(inv => inv.universe === 1)} />
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    loading: state.research.universeData.loading,
    error: state.research.universeData.error,
    universe: state.research.universeData.universe,
});

const mapDispatchToProps = (dispatch) => {
    return {
        fetchUniverse: () => dispatch(fetchUniverse()),
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(InvestmentUniverse);
