import React, {Fragment} from 'react';
import { makeStyles, } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import DueDiligenceAddEdit from './AddEditDueDiligence';
import Grid from '@material-ui/core/Grid';
import { Paper } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQuery';
import DueDiligenceAutoComplete from './DueDiligenceAutoComplete';
import Slide from '@material-ui/core/Slide/Slide';
import {addDueDiligence, updateDueDiligence, deleteDueDiligence} from "../../../../actions/researchActions";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DueDiligenceCollapsibleTable from "./DueDiligenceCollapsibleTable";

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        width: '100%',
        height: '100vh',
        marginTop: 5,
    },
    gridPaper: {
        // padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        //minHeight: 450,
    },
    gridPaperAutoComplete: {
        // padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        marginBottom: 6,
    },
    gridPaperAddEdit: {
        // padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        //minHeight: 450,
        marginTop: 5,
    },
}));

const DueDiligence = ({ loading,
                                    error,
                                    dueDiligenceSteps,
                                    dueDiligenceRecords,
                                    addDueDiligence,
                                    updateDueDiligence,
                                    deleteDueDiligence}, props) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    //const theme = useTheme();
    //const mobile = useMediaQuery(theme.breakpoints.up('sm'));

    //const [isAddMode, setIsAddMode] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({});
    const [selectedIdkDD, setSelectedIdkDD] = React.useState({});
    const [showAddEdit, setShowAddEdit] = React.useState(false);
    const [ddAction, setDDAction] = React.useState({idkDD_Action: ''});

    const handleAddEditClicked = (event) => {

        const id = event.currentTarget.dataset.id ? event.currentTarget.dataset.id :
            event.currentTarget.parentNode.parentNode.dataset.id

        if (id) {
            //grab record to send to add/edit
            let ddRecord = dueDiligenceRecords.filter(
                (rec) => String(rec.idkDD_stock) === id
            );

            setInitialValues(ddRecord[0]);
            setShowAddEdit(true);
            //setIsAddMode(false);
        } else {
            setInitialValues({ idkDD_Action: ddAction.idkDD_Action });
            setShowAddEdit(true);
            //setIsAddMode(true);
        }
    };

    const handleAutoCompleteAction = (idkDDAction) => {
        const action = dueDiligenceSteps.filter(step => step.idkDD_Action === idkDDAction)
        const idkDD_Action = idkDDAction ? idkDDAction : '';
        setDDAction(action[0]);
        setInitialValues({ idkDD_Action: idkDD_Action });
    };

    function handleSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (values.idkDD_stock) {
            updateDDRecord(values, resetForm);
        } else {
            createDDRecord(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteDueDiligence(values);
        setInitialValues({});
    }

    function createDDRecord(values, resetForm) {
        console.log(values)
        addDueDiligence(values);
        resetForm();
    }

    function updateDDRecord(values, resetForm) {
        updateDueDiligence(values);
        setInitialValues({});
        //resetForm({});
    }

    const handleCancel = () => {
        setInitialValues({ idkDD_Action: ddAction.idkDD_Action });
        setShowAddEdit(!showAddEdit);
    };

    return loading ? (<div className={classes.loading}><CircularProgress /></div>) :
             error ? (<div>{error}</div>) :
                 (<div className={classes.gridRoot}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <div style={{paddingRight: 5, width: mobile ? '100%' : '50%' }}>
                                <DueDiligenceAutoComplete
                                    ddAction={ddAction}
                                    handleAutoCompleteAction={handleAutoCompleteAction}
                                    dueDiligenceSteps={dueDiligenceSteps}
                                />
                            </div>
                        </Grid>
                            {!mobile ? (
                               <Fragment>
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.gridPaper}>
                                            <DueDiligenceCollapsibleTable
                                                mobile={mobile}
                                                selectedIdkDD={selectedIdkDD}
                                                setSelectedIdkDD={setSelectedIdkDD}
                                                ddAction={ddAction}
                                                dueDiligenceRecords={dueDiligenceRecords}
                                                handleAddEditClicked={handleAddEditClicked}
                                            />
                                        </Paper>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Paper className={classes.gridPaper}>
                                            <DueDiligenceAddEdit
                                                mobile={mobile}
                                                initialValues={initialValues}
                                                setShowAddEdit={setShowAddEdit}
                                                onSubmit={handleSubmit}
                                                handleDelete={handleDelete}
                                                handleCancel={handleCancel}
                                            />
                                        </Paper>
                                    </Grid>
                               </Fragment>
                            ) : (
                               <Fragment>
                                    {!showAddEdit ? (
                                        <Grid item xs={12} sm={6}>
                                            <Paper className={classes.gridPaper}>
                                                <DueDiligenceCollapsibleTable
                                                    mobile={mobile}
                                                    selectedIdkDD={selectedIdkDD}
                                                    setSelectedIdkDD={setSelectedIdkDD}
                                                    ddAction={ddAction}
                                                    dueDiligenceRecords={dueDiligenceRecords}
                                                    handleAddEditClicked={handleAddEditClicked}
                                                />
                                            </Paper>
                                        </Grid>
                                    ) : (
                                        <Grid item xs={12} sm={6}>
                                            <Slide
                                                direction="left"
                                                in={true}
                                                mountOnEnter
                                                unmountOnExit
                                            >
                                                <Paper className={classes.gridPaper}>
                                                    <DueDiligenceAddEdit
                                                        mobile={mobile}
                                                        initialValues={initialValues}
                                                        setShowAddEdit={setShowAddEdit}
                                                        onSubmit={handleSubmit}
                                                        handleDelete={handleDelete}
                                                        handleCancel={handleCancel}
                                                    />
                                                </Paper>
                                            </Slide>
                                        </Grid>
                                    )}
                               </Fragment>
                            )}
                        </Grid>
                 </div>);
};

DueDiligence.propTypes = {};

const mapStateToProps = (state) => ({
    loading: state.research.dueDiligenceData.error,
    error: state.research.dueDiligenceData.loading,
    dueDiligenceSteps: state.research.dueDiligenceData.stockDueDiligence.dueDiligenceSteps,
    dueDiligenceRecords: state.research.dueDiligenceData.stockDueDiligence.dueDiligenceRecords,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addDueDiligence: (invDueDiligence) => dispatch(addDueDiligence(invDueDiligence)),
        updateDueDiligence: (invDueDiligence) => dispatch(updateDueDiligence(invDueDiligence)),
        deleteDueDiligence: (invDueDiligence) => dispatch(deleteDueDiligence(invDueDiligence))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(DueDiligence);
