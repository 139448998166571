import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field, Form, Formik } from 'formik';
import TD3FormikSelectField from '../../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField';
import {connect} from "react-redux";
import AddEditToolBar from "../../../common/AddEditToolBar";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #6d6d6d',
    },
}));

function AddEditThematic(props) {
    const classes = useStyles();

    const { idk_stock_themes,
               idk_themesecondary,
               weight,
               note } = {...props.initialValues,};

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idk_stock_themes: values.idk_stock_themes,
                idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: props.investment,
                    idk_stock_themes: idk_stock_themes ? idk_stock_themes : '',
                    idk_themesecondary: idk_themesecondary ? idk_themesecondary : '',
                    weight: weight ? weight : '',
                    note: note ? note : '',
                }}
                onSubmit={props.onSubmit}
            >
                {({ values, dirty, touched,}) => (
                    <Form >
                        <AddEditToolBar
                            mobile={props.mobile}
                            combined={true}
                            handleCancel={props.handleCancel}
                            handleDelete={handleDeleteConfirmed}
                            saveDisabled={!dirty}
                            cancelDisabled={!dirty}
                            deleteDisabled={!values.idk_stock_themes}
                            setShowAddEdit={props.setShowAddEdit}
                            values={values}
                        />
                        <div className={classes.selectGroup}>
                            <div style={{ flex: 2, padding: 5 }}>
                                <TD3FormikSelectField
                                    style={{width: '100%'}}
                                    name="idk_themesecondary"
                                    label="Theme"
                                    margin="normal"
                                    variant="outlined"
                                    options={props.optionValues.themeOptions ?
                                        props.optionValues.themeOptions.map(opt =>{
                                            return {label: opt.secondary_theme_name, value: opt.idk_themesecondary}
                                        }) : []}
                                />
                            </div>
                            <div style={{flex: 1, padding: 5 }}>
                                <Field
                                    type="input"
                                    name="weight"
                                    variant="outlined"
                                    label="Weight"
                                    as={TextField}
                                />
                            </div>
                        </div>

                        <div style={{padding: 5 }}>
                            <Field
                                //fullwidth
                                style={{ width: '100%'}}
                                type="input"
                                name="note"
                                variant="outlined"
                                label="Note"
                                multiline
                                rows={11}
                                value={values.note}
                                as={TextField}
                            />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapStateToProps = (state) => ({
    investment: state.research.currentInvestment.investment,
    optionValues: state.research.optionValues,
});

export default connect(mapStateToProps, {})(AddEditThematic);

// AddEditThematic.propTypes = {
//     thematicRecord: PropTypes.shape({
//        'idkStockThemes': PropTypes.number,
//        'ticker': PropTypes.string,
//        'secondaryThemeName': PropTypes.string,
//        'weight': PropTypes.number,
//        'note': PropTypes.string,
//     })
//};
