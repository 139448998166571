import { OPEN_MODAL_ADD_ORG, CLOSE_MODAL_ADD_ORG } from '../actions/types';

export const openAddOrgModal = () => (dispatch) => {
    dispatch({
        type: OPEN_MODAL_ADD_ORG,
        payload: true,
    });
};

export const closeAddOrgModal = () => (dispatch) => {
    dispatch({
        type: CLOSE_MODAL_ADD_ORG,
        payload: false,
    });
};
