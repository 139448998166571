import {
    LOGIN_USER,
    LOGIN_ERROR,
    LOGOUT_USER,
    CHECK_USER_TOKEN,
} from '../actions/types';

const initialState = {
    loggedin: false,
    user: '',
    roles: [],
    error: '',
};

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:
            console.log('User ' + action.payload.user_name + ' logged in.');
            return {
                ...state,
                loggedin: action.payload.loggedin,
                user: action.payload.user_name,
                roles: [],
                error: '',
            };
        case CHECK_USER_TOKEN:
            return {
                ...state,
                loggedin: action.payload.loggedin,
                user: action.payload.user_name,
                roles: [],
                error: '',
            };
        case LOGIN_ERROR:
            return {
                ...state,
                error: action.payload.error,
            };
        case LOGOUT_USER:
            return {
                ...state,
                loggedin: action.payload.loggedin,
                user: action.payload.user_name,
                roles: [],
                error: '',
            };

        default:
            return state;
    }
}
