import React, {Component, Fragment} from 'react'
import PrimarySearchAppBar from "../../components/AppBar/PrimarySearchAppBar";
import ChangeUserForm from "../../components/UserAdminForm/ChangeUserForm";

export default class UserAdminUpdatePage extends Component {
  static defaultProps = {
    history: {
      push: () => {},
    },
  }

  render() {
    return (
        <Fragment>
            <PrimarySearchAppBar />
            <div style={{display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", textAlign: 'center'}}>
                    <h2>Change User Password</h2>
                    <ChangeUserForm/>
            </div>
        </Fragment>
    )
  }
}
