import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const useStyles = makeStyles((theme) => ({
    name: {
        flex: 1,
        height: '100%',
        textAlign: 'left',
    },
}));

const Name = ({passFailData, industryOptions, dueDiligenceRecords}) => {
    // const [initialValues, setInitialValues] = React.useState({});
    const {name, ticker,} = passFailData.stockDetails ? passFailData.stockDetails : {};
    const classes = useStyles();


    const getIndustry = (industryOptions = [], records = []) => {
        const industry = records.filter(rec => rec.idkDD_Action === 17)
        const idk = industry[0] ? industry[0].idkCyclicalIndustry : -1
        const name = industryOptions.filter(ind => ind.idk_evo_industry === idk)

        return name[0] ? name[0].evo_industry_name : ''
    }


    return passFailData.loading ? (
        <div className={classes.loading}><CircularProgress /></div>
    ) : passFailData.error ? (
        <div>
            <Typography variant="body2">
                {JSON.parse(passFailData.error)}
            </Typography>
        </div>
    ) : (
        <div className={classes.name}>
            <Typography
                className={classes.title}
                color="textPrimary"
                gutterBottom
            >
                <b>{ticker}</b>
            </Typography>
            <Typography variant="h5">
                {name}
            </Typography>
            <br />
            <Typography
                variant="body2"
                className={classes.pos}
                color="textSecondary"
            >
                Evolytical Industry : <b>{getIndustry(industryOptions, dueDiligenceRecords)}</b>
            </Typography>
            <br />
        </div>
    );
};

Name.propTypes = {};

const mapStateToProps = (state) => ({
    passFailData: state.research.passFailData,
    industryOptions: state.research.optionValues.industryOptions,
    dueDiligenceRecords: state.research.dueDiligenceData.stockDueDiligence.dueDiligenceRecords,
});

export default connect(mapStateToProps, {})(Name);
