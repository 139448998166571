import React from 'react';
import { Field } from 'formik';
import SelectField from './TD3SelectField';

const TD3FormikSelectField = ({ render, ...props }) => {
    return <Field {...props} component={SelectField} />;
};

// if (process.env.NODE_ENV !== 'production') {
//     TD3FormikSelectField.propTypes = {
//         render: PropTypes.func,
//     };
// }

export default TD3FormikSelectField;
