import React from 'react';
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpIcon from "@material-ui/icons/Help";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Button from "@material-ui/core/Button";

const AddEditToolBar = ({
                            mobile,
                            combined,
                            handleCancel,
                            handleDelete,
                            handleGuideOpen,
                            saveDisabled,
                            cancelDisabled,
                            deleteDisabled,
                            setShowAddEdit,
                            values}) => {
    const [openConfirm, setConfirmOpen] = React.useState(false);

    const handleConfirmClose = (e, values) =>{
        handleDelete(e, values)
        setConfirmOpen(false);
    };
    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    return (
        <div style={{ height: mobile ? 48.8 : 56.8,
                           borderRadius: '5px 5px 0px 0px',
                           backgroundColor: '#303030',
                           display: "flex", alignItems: "center",
                           justifyContent: "space-between"}}>
            <div>
                {combined ? (
                    <IconButton
                        onClick={() => {
                            setShowAddEdit(false);
                        }}
                        aria-label="back"
                    >
                        <ArrowBackIcon />
                    </IconButton>
                ) : null}
                <IconButton disabled={saveDisabled} type="submit" aria-label="save">
                    <SaveIcon />
                </IconButton>
                <IconButton
                    disabled={cancelDisabled}
                    onClick={handleCancel}
                    type="reset"
                    aria-label="cancel"
                >
                    <CancelIcon />
                </IconButton>
                <span style={{padding: 5}}/>
                <span style={{borderLeft: '2px solid #6d6d6d', padding: 5}}/>
                <IconButton
                    onClick={() => {
                        handleConfirmOpen();
                    }}
                    disabled={deleteDisabled}
                    aria-label="delete-confirm"
                >
                    <DeleteIcon />
                </IconButton>
            </div>
            <div>
                <IconButton onClick={handleGuideOpen}>
                    <HelpIcon />
                </IconButton>
            </div>
            <Dialog
                open={openConfirm}
                onClose={handleConfirmClose}
                aria-labelledby="delete-confirm-dialog-title">
                <DialogTitle id="delete-confirm-dialog-title">
                    {`Confirm delete.`}
                </DialogTitle>
                <DialogActions>
                    <Button id='yes-delete' onClick={(e) => handleConfirmClose(e, values)} color="primary" autoFocus>
                        Yes
                    </Button>
                    <Button id='no-delete' onClick={(e) => handleConfirmClose(e, values)} color="primary" autoFocus>
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddEditToolBar;
