import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import {DatePicker,} from 'formik-material-ui-pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from "@date-io/date-fns";
import TokenService from "../../../../../services/token-service";
import AddEditToolBar from "../../../common/AddEditToolBar";
import TD3FormikSelectField from "../../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #6d6d6d',
    },
    numberField: {
        flex: 1,
        margin: 1,
        '& .MuiInputBase-input::-webkit-outer-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input::-webkit-inner-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input[type=number]' : {'-moz-appearance': 'textfield'},
    }
}));

function AddEditBigMove(props) {
    const classes = useStyles();
    const {
        idkDD_stock,
        enteredby,
        editedby,
        BM_Date,
        DD_Value,
        DD_Status,
        DD_Conviction,
        DD_Rank,
        DD_HyperLink,
        idkDD_Memo,
        Memo
    } = {
        ...props.initialValues,
    };

    const getDateTime = () =>{
        return new Date().toISOString();
    }

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idkDD_stock: values.idkDD_stock,
                idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: props.investment,
                    idkDD_stock: idkDD_stock ? idkDD_stock : '',
                    idkDD_Action: 52,
                    DD_Value: DD_Value ? DD_Value : '',
                    BM_Date: BM_Date? BM_Date : getDateTime(),
                    DD_Status: DD_Status ? DD_Status : '',
                    DD_Conviction: DD_Conviction ? DD_Conviction : 5,
                    DD_Rank: DD_Rank ? DD_Rank : 5,
                    DD_HyperLink: DD_HyperLink ? DD_HyperLink : '',
                    idkDD_Memo: idkDD_Memo ? idkDD_Memo : '',
                    Memo: Memo ? Memo : '',
                    enteredby: enteredby ? enteredby : TokenService.getCurrentUser(),
                    editedby: editedby ? editedby : TokenService.getCurrentUser(),
                }}
                onSubmit={props.onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form>
                        <AddEditToolBar
                            mobile={props.mobile}
                            combined={true}
                            handleCancel={props.handleCancel}
                            handleDelete={handleDeleteConfirmed}
                            saveDisabled={!dirty}
                            cancelDisabled={!dirty}
                            deleteDisabled={!values.idkDD_stock}
                            setShowAddEdit={props.setShowAddEdit}
                            values={values}
                        />
                     <div className={classes.selectGroup}>
                            <div style={{ padding: 5 }}>
                                <Field
                                    style={{ width: '100%' }}
                                    type="input"
                                    name="DD_Value"
                                    variant="outlined"
                                    label="Performance"
                                    as={TextField}
                                />
                            </div>
                            <div style={{flex: 1, padding: 5 }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <Field style={{ width: '100%' }}
                                           component={DatePicker}
                                           variant='outline'
                                           format='MM-dd-yyyy'
                                           name="BM_Date"
                                           label="Move Date" />
                                </MuiPickersUtilsProvider>
                            </div>
                        </div>
                        <div style={{display: 'flex'}}>
                            <TD3FormikSelectField
                                name="DD_Status"
                                label="Category"
                                margin="normal"
                                style={{ flex: 1, margin: 1 }}
                                variant="outlined"
                                options={[
                                    { label: '', value: '' },
                                    { label: 'Unknown', value: 'unknown' },
                                    {
                                        label: 'Buy or Sell Event',
                                        value: 'buysell',
                                    },
                                    {
                                        label: 'News & Info',
                                        value: 'news',
                                    },
                                ]}
                            />
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Conviction"
                                label="Conviction"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Conviction'/>}
                                as={TextField}
                            />
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Rank"
                                label="Rank"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Rank'/>}
                                as={TextField}
                            />
                        </div>
                        <div className={classes.selectGroup}>
                            <Field
                                style={{ flex: 1, margin: 1 }}
                                type="input"
                                name="DD_HyperLink"
                                label="Link"
                                variant="outlined"
                                value={values.DD_HyperLink}
                                as={TextField}
                            />
                        </div>
                        <div style={{ padding: 5 }}>
                            <Field
                                style={{ width: '100%' }}
                                type="input"
                                name="Memo"
                                variant="outlined"
                                label="Note"
                                multiline
                                rows={11}
                                value={values.Memo}
                                as={TextField}
                            />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapStateToProps = (state) => ({
    investment: state.research.currentInvestment.investment,
});

export default connect(mapStateToProps, {})(AddEditBigMove);
