import React, {Component, Fragment} from 'react'
import CreateUserForm from "../../components/UserAdminForm/CreateUserForm";
import PrimarySearchAppBar from "../../components/AppBar/PrimarySearchAppBar";

export default class UserAdminCreatePage extends Component {
  static defaultProps = {
    history: {
      push: () => {},
    },
  }
    state = {createUser: true}

  render() {
    return (
        <Fragment>
            <PrimarySearchAppBar />
            <div style={{display: "flex", flexDirection: "column", alignItems: 'center', justifyContent: "center",textAlign: 'center'}}>
                    <h2>Create New User</h2>
                    <CreateUserForm/>
            </div>
        </Fragment>
    )
  }
}
