import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import {FieldArray} from "formik";
import ListItemStrategy from "./ListItemStrategy";

const useStyles = makeStyles((theme) => ({
     title: {
        width: '100%',
        textAlign: 'center',
        borderBottom: '1px solid #6d6d6d',
         padding: '8px 0px 8px 0',
    },
}));

const Strategy = ({values,setFieldValue, dirty,syncStrategyDetails}) => {
    const classes = useStyles();
   return (
        <div>
            <div className={classes.title}>
                <Typography variant="h6">Strategies</Typography>
            </div>
            <FieldArray name="strategies">
               {(arrayHelpers) => (
                    <div>
                        {values.strategies.map((strategy,idx) =>{
                            return (
                                <ListItemStrategy
                                    key={strategy.idk_strategy_qualifier}
                                    idkPassFail={`strategies.${idx}.idkPassFail`}
                                    pfRational={`strategies.${idx}.pfRational`}
                                    strategy={strategy}
                                    dirty={dirty}
                                    syncStrategyDetails={syncStrategyDetails}
                                    setFieldValue={setFieldValue}
                                />
                            )})}
                    </div>
                )}

            </FieldArray>
        </div>
    );
};

Strategy.propTypes = {};

const mapStateToProps = (state) => ({
    //currentStock: state.research.currentStock,
    passFailData: state.research.passFailData,
    optionValues: state.research.optionValues,
});

export default connect(mapStateToProps, {})(Strategy);
