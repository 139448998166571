import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from './TD3TextField';
// import { optionShape } from '../utils/PropTypes';

const TD3SelectField = ({
    options,
    multiple = false,
    native = false,
    InputLabelProps,
    SelectProps,
    ...props
}) => {
    const isNative = !multiple && native;
    return (
        <TextField
            {...props}
            InputLabelProps={{
                shrink: isNative === true ? true : undefined,
                ...InputLabelProps,
            }}
            SelectProps={{
                ...SelectProps,
                multiple,
                native: isNative,
            }}
            select
        >
            {isNative
                ? options.map((option) => (
                      <option key={option.value} value={option.value}>
                          {option.label}
                      </option>
                  ))
                : options.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                          {option.label}
                      </MenuItem>
                  ))}
        </TextField>
    );
};

// if (process.env.NODE_ENV !== 'production') {
//     TD3SelectField.propTypes = {
//         options: PropTypes.arrayOf(optionShape).isRequired,
//         multiple: PropTypes.bool,
//         native: PropTypes.bool,
//         InputLabelProps: PropTypes.object,
//         SelectProps: PropTypes.object,
//     };
// }

export default TD3SelectField;
