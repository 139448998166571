import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { connect } from 'react-redux';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import * as Yup from "yup";
import TD3FormikSelectField from '../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField';
import TokenService from "../../../../services/token-service";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import HelpIcon from '@material-ui/icons/Help';
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import AddEditToolBar from "../../common/AddEditToolBar";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
        marginTop: 5
    },
    numberField: {
        flex: 1,
        margin: 5,
        '& .MuiInputBase-input::-webkit-outer-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input::-webkit-inner-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input[type=number]' : {'-moz-appearance': 'textfield'},

    }
}));

const AddEditTranScore = (props) => {
    const classes = useStyles();
    //const isAddMode = !props.initialValues.id;
    const { idkts, followup, score, note, idkTransScoreCategory, enteredby,} = {
        ...props.initialValues,
    };

    const [openGuide, setOpenGuide] = React.useState(false);
    const [openConfirm, setConfirmOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const handleGuideOpen = () => {
        setOpenGuide(true);
    };

    const handleGuideClose = () => {
        setOpenGuide(false);
    };

    const handleConfirmOpen = () => {
        setConfirmOpen(true);
    };

    const handleConfirmClose = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idkts: values.idkts,
            idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
        setConfirmOpen(false);
    };

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idkts: values.idkts,
                idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
    };

    const [placeHolder, setPlaceHolder] = React.useState({title: '',
                                                                   guideline: 'Select a category to see guidelines',});
    const syncPlaceHolder = (event) => {
        let selectedOption = props.optionValues.categoryOptions.filter(
            (option) => option.idkTransScoreCategory === event.target.value);
        setPlaceHolder({title: selectedOption[0].transScoreCategoryName,
                              guideline: selectedOption[0].transScoreCategoryPlaceholder,});
    }

    const validationSchema = Yup.object({
        score: Yup.number().when('idkTransScoreCategory', {
            is: 7,
            then: Yup.number().moreThan(-11,'Must be between -10 and 10' )
                     .lessThan(11,'Must be between -10 and 10' ).required('Required'),
            otherwise: Yup.number().moreThan(-1,'Must be between 0 and 10' )
        .lessThan(11,'Must be between 0 and 10' ).required('Required')
        }),
        idkTransScoreCategory: Yup.number().required('Required'),
        note: Yup.string().required('Require')
    });

    const initialValues = {
        idkts: idkts ? idkts : '',
        idkInvestment: props.investment,
        enteredby: enteredby ? enteredby : TokenService.getCurrentUser(),
        followup: followup ? followup : 0,
        score: score ? score : 0,
        note: note ? note : '',
        idkTransScoreCategory: idkTransScoreCategory ? idkTransScoreCategory : '',
        editedby: TokenService.getCurrentUser(),
    };

    return props.transScoreData.loading ? (
        <div className={classes.loading}><CircularProgress /></div>
    ) : props.transScoreData.error ? (
        <div>{props.transScoreData.error}</div>
    ) : (
          <Formik
              enableReinitialize={true}
              initialValues={{
                  ...initialValues,
              }}
              validationSchema={validationSchema}
              onSubmit={props.handleSubmit}
        >
             {({ values, dirty, errors}) => (
                    <Form>
                            <AddEditToolBar
                                mobile={props.mobile}
                                combined={props.mobile}
                                handleCancel={props.handleCancel}
                                handleDelete={handleDeleteConfirmed}
                                handleGuideOpen={handleGuideOpen}
                                saveDisabled={!dirty}
                                cancelDisabled={!dirty}
                                deleteDisabled={!values.idkts}
                                setShowAddEdit={props.setShowAddEdit}
                                values={values}
                            />
                        <div className={classes.selectGroup}>
                            <Field
                                style={{ flex: 1, margin: 1 }}
                                type="input"
                                name="enteredby"
                                label="Analyst"
                                variant="outlined"
                                disabled
                                as={TextField}
                                helperText={<ErrorMessage name='analyst'/>}
                            />

                            <TD3FormikSelectField
                                name="followup"
                                label="Follow-up"
                                margin="normal"
                                style={{ flex: 1, margin: 1 }}
                                variant="outlined"
                                options={[
                                    { label: '', value: '' },
                                    { label: 'Yes', value: 1 },
                                    { label: 'No', value: 0 },
                                ]}
                                helperText={<ErrorMessage name='followup'/>}
                            />
                        </div>
                        <div className={classes.selectGroup}>
                            <TD3FormikSelectField
                                name="idkTransScoreCategory"
                                label="Category"
                                margin="normal"
                                style={{ flex: 1, margin: 1 }}
                                variant="outlined"
                                onChange={syncPlaceHolder}
                                helperText={<ErrorMessage name='category'/>}
                                options={props.optionValues.categoryOptions.map(opt =>{
                                        return {label: opt.transScoreCategoryName,
                                                value: opt.idkTransScoreCategory}})}
                            />

                            <Field
                                className={classes.numberField}
                                type="number"
                                name="score"
                                label="Score"
                                variant="outlined"
                                error={!!errors.score}
                                helperText={<ErrorMessage name='score'/>}
                                as={TextField}
                            />
                        </div>
                        <div style={{ padding: 5 }}>
                                    <Field
                                        style={{ width: '100%' }}
                                        type="input"
                                        name="note"
                                        variant="outlined"
                                        label="Note"
                                        multiline
                                        rows={11}
                                        as={TextField}
                                        helperText={<ErrorMessage name='note'/>}
                                    />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                        <Dialog
                            fullScreen={fullScreen}
                            open={openGuide}
                            onClose={handleGuideClose}
                            aria-labelledby="trans-score-dialog-title"
                        >
                            <DialogTitle id="trans-score-dialog-title">
                                {`${placeHolder.title} Transformation Score Guidelines`}
                            </DialogTitle>
                            <DialogContent>
                                {placeHolder.guideline.split("\n").map((text, key) => {
                                    const indent = text.includes('&#09');
                                    return  <DialogContentText style={{marginLeft: indent ? 30 : 0}}
                                                               key={key}
                                                               variant='caption'>
                                        {indent ? text.substring(4) : text}
                                    </DialogContentText>
                                })}
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleGuideClose} color="primary" autoFocus>
                                    OK
                                </Button>
                            </DialogActions>
                        </Dialog>
                    <Dialog
                            open={openConfirm}
                            onClose={handleConfirmClose}
                            aria-labelledby="delete-confirm-dialog-title">
                        <DialogTitle id="delete-confirm-dialog-title">
                            {`Confirm delete.`}
                        </DialogTitle>
                        <DialogActions>
                            <Button id='yes-delete' onClick={(e) => handleConfirmClose(e, values)} color="primary" autoFocus>
                                Yes
                            </Button>
                            <Button id='no-delete' onClick={(e) => handleConfirmClose(e, values)} color="primary" autoFocus>
                                No
                            </Button>
                        </DialogActions>
                    </Dialog>
                    </Form>
                )}
          </Formik>
    );
};

AddEditTranScore.propTypes = {};

const mapStateToProps = (state) => ({
    investment: state.research.currentInvestment.investment,
    transScoreData: state.research.transScoreData,
    optionValues: state.research.optionValues,
});

export default connect(mapStateToProps, {})(AddEditTranScore);
