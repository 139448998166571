import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import QualPassFail from './Passfail/PassFail';
import CompetitorTheme from './CompetitorTheme/CompetitorTheme';
import TransformationalScore from './TransformationalScore/TransformationalScore';
import DueDiligence from './DueDiligence/DueDiligence';
import BigMoveNote from './BigMoveNote/BigMoveNote';

function QualitativeTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <Typography
            component="div"
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={1}>{children}</Box>}
        </Typography>
    );
}

QualitativeTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.background.paper,
        width: '100%',
        minHeight: '100vh',
    },
}));

function InvestmentDetailTabs() {
    const classes = useStyles();
    const theme = useTheme();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="default">
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                    aria-label="full width qualitative tabs"
                >
                    <Tab label="Pass-Fail" {...a11yProps(0)} />
                    <Tab label="Trans Score" {...a11yProps(1)} />
                    <Tab label="Due Diligence" {...a11yProps(2)} />
                    <Tab label="Moves & Notes" {...a11yProps(3)} />
                    <Tab label="Themes & Comps" {...a11yProps(4)} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
            >
                <QualitativeTabPanel
                    value={value}
                    index={0}
                    dir={theme.direction}
                    padding={1}
                >
                    <QualPassFail />
                </QualitativeTabPanel>
                <QualitativeTabPanel
                    value={value}
                    index={1}
                    dir={theme.direction}
                >
                    <TransformationalScore />
                </QualitativeTabPanel>
                <QualitativeTabPanel
                    value={value}
                    index={2}
                    dir={theme.direction}
                >
                    <DueDiligence />
                </QualitativeTabPanel>
                <QualitativeTabPanel
                    value={value}
                    index={3}
                    dir={theme.direction}
                >
                    <BigMoveNote />
                </QualitativeTabPanel>
                <QualitativeTabPanel
                    value={value}
                    index={4}
                    dir={theme.direction}
                >
                    <CompetitorTheme />
                </QualitativeTabPanel>
            </SwipeableViews>
        </div>
    );
}


export default InvestmentDetailTabs;
