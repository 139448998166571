import React, { Fragment } from 'react';
import { fade, makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import MenuIcon from '@material-ui/icons/Menu';
import SearchIcon from '@material-ui/icons/Search';
import SideDrawer from '../SideDrawer/SideDrawer';

const useStyles = makeStyles((theme) => ({
    grow: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginRight: theme.spacing(2),
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
            width: 'auto',
        },
    },
    searchIcon: {
        width: theme.spacing(7),
        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 7),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: 200,
        },
    },
    sectionDesktop: {
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'flex',
        },
    },
    sectionMobile: {
        display: 'flex',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
}));

export default function PrimarySearchAppBar(props) {
    const classes = useStyles();

    const searchPlaceholder = 'Search ' + props.placeHolder;

    //const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawer, setDrawer] = React.useState({ left: false });
    //const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

    //const isMenuOpen = Boolean(anchorEl);
    //const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    // const handleProfileMenuOpen = event => {
    //     setAnchorEl(event.target);
    // };

    // const handleMobileMenuClose = () => {
    //     setMobileMoreAnchorEl(null);
    // };

    // const handleMenuClose = () => {
    //     setAnchorEl(null);
    //     handleMobileMenuClose();
    // };

    // const handleMobileMenuOpen = (event) => {
    //     setMobileMoreAnchorEl(event.currentTarget);
    // };

    const handleDrawerOpen = (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return;
        }

        if (
            event.target.children[0] &&
            event.target.children[0].innerText === 'Add'
        ) {
            return;
        }

        setDrawer({ left: drawer.left ? false : true });
    };

    //const mobileMenuId = 'primary-search-account-menu-mobile';
    // const renderMobileMenu = (
    //     <Menu
    //         anchorEl={mobileMoreAnchorEl}
    //         anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         id={mobileMenuId}
    //         keepMounted
    //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         open={isMobileMenuOpen}
    //         onClose={handleMobileMenuClose}
    //     >
    //         <MenuItem>
    //             <IconButton
    //                 aria-label="show 11 new notifications"
    //                 color="inherit"
    //             >
    //                 <Badge badgeContent={11} color="primary">
    //                     <NotificationsIcon />
    //                 </Badge>
    //             </IconButton>
    //             <p>Notifications</p>
    //         </MenuItem>
    //     </Menu>
    // );

    return (
        <Fragment>
            <SideDrawer open={drawer} toggle={handleDrawerOpen} />
            <div className={classes.grow}>
                <AppBar position="static" color="default">
                    <Toolbar>
                        <IconButton
                            edge="start"
                            className={classes.menuButton}
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            className={classes.title}
                            variant="h6"
                            noWrap
                        >
                            {process.env.NODE_ENV === 'development' ?
                                <span><span style={{color : 'red'}}> DEV</span>olytical</span> : <span>Evolytical</span>}
                        </Typography>
                        <div className={classes.search}>
                            <div className={classes.searchIcon}>
                                <SearchIcon />
                            </div>
                            <InputBase
                                placeholder={searchPlaceholder}
                                classes={{
                                    root: classes.inputRoot,
                                    input: classes.inputInput,
                                }}
                                inputProps={{ 'aria-label': 'search' }}
                            />
                        </div>
                        <div className={classes.grow} />
                        {/*<div className={classes.sectionDesktop}>*/}
                        {/* <IconButton aria-label="show 4 new mails" color="inherit">*/}
                        {/*        <Badge badgeContent={4} color="secondary">*/}
                        {/*            <MailIcon />*/}
                        {/*        </Badge>*/}
                        {/*    </IconButton> *!/*/}
                        {/*    <IconButton*/}
                        {/*        aria-label="show 17 new notifications"*/}
                        {/*        color="inherit"*/}
                        {/*    >*/}
                        {/*        <Badge badgeContent={17} color="primary">*/}
                        {/*            <NotificationsIcon />*/}
                        {/*        </Badge>*/}
                        {/*    </IconButton>*/}
                        {/*    <IconButton*/}
                        {/*        edge="end"*/}
                        {/*        aria-label="account of current user"*/}
                        {/*        aria-controls={menuId}*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        onClick={handleProfileMenuOpen}*/}
                        {/*        color="inherit"*/}
                        {/*    >*/}
                        {/*        <AccountCircle />*/}
                        {/*    </IconButton> */}
                        {/*</div>*/}
                        {/*<div className={classes.sectionMobile}>*/}
                        {/*    <IconButton*/}
                        {/*        aria-label="show more"*/}
                        {/*        aria-controls={mobileMenuId}*/}
                        {/*        aria-haspopup="true"*/}
                        {/*        onClick={handleMobileMenuOpen}*/}
                        {/*        color="inherit"*/}
                        {/*    >*/}
                        {/*        <MoreIcon />*/}
                        {/*    </IconButton>*/}
                        {/*</div>*/}
                    </Toolbar>
                </AppBar>
                {/*{renderMobileMenu}*/}
                {/* {renderMenu} */}
            </div>
        </Fragment>
    );
}
