import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Redirect} from "react-router-dom";
import {connect} from "react-redux";
import {loginAction} from "../../actions/authActions";
import heroImage from "../../static/images/heroColor.png"
import Slide from "@material-ui/core/Slide";
import Fade from "@material-ui/core/Fade";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://www.evolytical.com/" target="_blank">
                Evolytical
            </Link>
        </Typography>
    );
}

const handleSubmitJwtAuth = (props) => (event) => {
    event.preventDefault();

    const { user_name, password } = event.target;
    const user = {
        user_name: user_name.value.toLowerCase(),
        password: password.value,
    };
    props.loginAction(user);
};

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage: `linear-gradient(
                                rgba(66, 66, 66, .95),
                                rgba(66, 66, 66, .05)),
                                url(${heroImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },
    zIndex200HeroText: {
        zIndex: 200,
        position: 'absolute',
        left: 50,
        top: 35
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

function LoginFormSide(props) {
    const classes = useStyles();
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const { loggedin, error } = props.auth;
    if (loggedin === true) {
        return <Redirect to="/universe" />;
    }

    return (
        <Grid container component="main" className={classes.root}>
            {/*<CssBaseline />*/}
            <Fade in={true} timeout={600}>
            <Grid item xs={12} sm={4} md={6} className={classes.image} style={{padding: mobile ? 0 : 20}}>
                <Slide direction='down' in={true} timeout={850} mountOnEnter unmountOnExit>
                    <div style={{textAlign: mobile ? "center" : "left",
                                 margin: mobile ? 0 : '0px 0px 0px 10px',}} >
                        <Typography style={{fontWeight: 'bold'}}
                                    component="h1"
                                    variant={mobile ? "h4" : "h1"}>
                            Transcend
                        </Typography>
                    </div>
                </Slide>
                <Slide direction='right' in={true} timeout={1050} mountOnEnter unmountOnExit>
                    <div style={{textAlign: mobile ? "center" : "left",
                                 margin: mobile ? 0 : '0px 0px 0px 41px',}} >
                        <Typography style={{transformOrigin: mobile ? null : '30px 30px 0px',
                                            transform: mobile ? null : 'rotate(90deg)',
                                            }}
                                    component="h1"
                                    variant={mobile ? "h4" : "h1"}>
                            Transform
                        </Typography>
                    </div>
                </Slide>
                </Grid>
            </Fade>
            <Grid item container direction="column" justify="center" alignItems="center"
                  xs={12} sm={8} md={6} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Typography variant= {mobile ? "h5" : "h4"}>
                        {process.env.NODE_ENV === 'development' ?
                            <span><span style={{color : 'red'}}> DEV</span>olytical</span> : <span>Evolytical</span>}
                    </Typography>
                    <div role="alert">
                        {error && (
                            <p style={{ color: 'red', fontWeight: 'bold' }}>
                                {error}
                            </p>
                        )}
                    </div>
                    <form
                        className={classes.form}
                        onSubmit={handleSubmitJwtAuth(props)}
                        noValidate
                    >
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            id="user_name"
                            label="User Name"
                            name="user_name"
                        />
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                        />
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                        >
                            Log In
                        </Button>
                    </form>
                    <Box mt={8}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { loginAction })(LoginFormSide);