import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Thematic from './Theme/Thematic';
import Competition from './Competitor/Competitor';
import { Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        width: '100%',
        height: '100vh',
        marginTop: 5,
    },
    gridPaper: {
        //padding: theme.spacing(1),
        //minHeight: 450,
        color: theme.palette.text.secondary,
        overflow: "hidden",
    },
}));

const CompetitorTheme = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.gridRoot}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.gridPaper}>
                        <Thematic />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper className={classes.gridPaper}>
                        <Competition />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

CompetitorTheme.propTypes = {};

export default CompetitorTheme;
