import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Slide from '@material-ui/core/Slide/Slide';
import AddEditBigMove from './AddEditBigMove';
import {addBigMove,
           deleteBigMove,
           updateBigMove} from "../../../../../actions/researchActions";
import BigMoveCollapsibleTable from "./BigMoveCollapsibleTable";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        color: '#649cb5',
        width: '100%',
        textAlign: 'center',
        paddingTop: 8,
        paddingBottom: 8,
    },
}));

const BigMove = ({  loading,
                             error,
                             bigMoves,
                             addBigMove,
                             updateBigMove,
                             deleteBigMove,}) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    const [initialValues, setInitialValues] = React.useState({ });
    const [showAddEdit, setShowAddEdit] = React.useState(false);

    const handleAddEditClicked = (event) => {

        if (event.currentTarget.parentNode.parentNode.dataset.id) {
            let id = event.currentTarget.parentNode.parentNode.dataset.id;
            //grab score to send to add/edit
            let move = bigMoves.filter(
                (move) => String(move.idkDD_stock) === id
            );
            setInitialValues(move[0]);
            setShowAddEdit(true);
        } else {
            setInitialValues({});
            setShowAddEdit(true);
        }
    };

    function onSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (values.idkDD_stock) {
            updateInvBigMove(values, resetForm);
        } else {
            createInvBigMove(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteBigMove(values);
        setInitialValues({});
    }

    function createInvBigMove(values, resetForm) {
        addBigMove(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    function updateInvBigMove(values, resetForm) {
        updateBigMove(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    const handleCancel = () => {
        console.log('Cancel');
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    };

    return loading ? (<div className={classes.loading}><CircularProgress /></div>) :
            error ? (<div>{error}</div>) : (
        <div>
            <div className={classes.title}>
                <Typography variant="h6">Big Move</Typography>
            </div>
            {!showAddEdit ? (
                <div>
                    <BigMoveCollapsibleTable
                        mobile={mobile}
                        bigMoves={bigMoves}
                        handleAddEditClicked={handleAddEditClicked}
                    />
                </div>
            ) : (
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div>
                        {console.log(mobile)}
                        <AddEditBigMove
                            mobile={mobile}
                            initialValues={initialValues}
                            setShowAddEdit={setShowAddEdit}
                            onSubmit={onSubmit}
                            handleDelete={handleDelete}
                            handleCancel={handleCancel}
                        />
                    </div>
                </Slide>
            )}
        </div>
    );
};

BigMove.propTypes = {};

const mapStateToProps = (state) => ({
    loading : state.research.bigMoveData.loading,
    error: state.research.bigMoveData.error,
    bigMoves: state.research.bigMoveData.bigMoves,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addBigMove: (invBigMove) => dispatch(addBigMove(invBigMove)),
        updateBigMove: (invBigMove) => dispatch(updateBigMove(invBigMove)),
        deleteBigMove: (invBigMove) => dispatch(deleteBigMove(invBigMove))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(BigMove);
