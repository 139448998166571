import {

    REHYDRATE_UNIVERSE,
    FETCH_UNIVERSE_REQUEST,
    FETCH_UNIVERSE_SUCCESS,
    FETCH_UNIVERSE_ERROR,

    FETCH_OPTION_VALUES_REQUEST,
    FETCH_OPTION_VALUES_SUCCESS,
    FETCH_OPTION_VALUES_ERROR,

    REHYDRATE_CURRENT_INVESTMENT,
    SET_CURRENT_INVESTMENT,
    SET_CURRENT_INVESTMENT_SUCCESS,
    SET_CURRENT_INVESTMENT_ERROR,

    FETCH_PASSFAIL_REQUEST,
    FETCH_PASSFAIL_SUCCESS,
    FETCH_PASSFAIL_ERROR,
    UPDATE_PASSFAIL_REQUEST,
    UPDATE_PASSFAIL_SUCCESS,
    UPDATE_PASSFAIL_ERROR,

    BIGMOVE_REQUEST_FETCH,
    BIGMOVE_REQUEST_ADD,
    BIGMOVE_REQUEST_UPDATE,
    BIGMOVE_REQUEST_DELETE,
    BIGMOVE_SUCCESS,
    BIGMOVE_ERROR,

    GENNOTE_REQUEST_FETCH,
    GENNOTE_REQUEST_ADD,
    GENNOTE_REQUEST_UPDATE,
    GENNOTE_REQUEST_DELETE,
    GENNOTE_SUCCESS,
    GENNOTE_ERROR,

    TRANSSCORE_REQUEST_FETCH,
    TRANSSCORE_REQUEST_ADD,
    TRANSSCORE_REQUEST_UPDATE,
    TRANSSCORE_REQUEST_DELETE,
    TRANSSCORE_SUCCESS,
    TRANSSCORE_ERROR,

    DUE_DILIGENCE_REQUEST_FETCH,
    DUE_DILIGENCE_REQUEST_ADD,
    DUE_DILIGENCE_REQUEST_UPDATE,
    DUE_DILIGENCE_REQUEST_DELETE,
    DUE_DILIGENCE_SUCCESS,
    DUE_DILIGENCE_ERROR,

    THEMATIC_REQUEST_FETCH,
    THEMATIC_REQUEST_ADD,
    THEMATIC_REQUEST_UPDATE,
    THEMATIC_REQUEST_DELETE,
    THEMATIC_SUCCESS,
    THEMATIC_ERROR,

    COMPETITOR_REQUEST_FETCH,
    COMPETITOR_REQUEST_ADD,
    COMPETITOR_REQUEST_UPDATE,
    COMPETITOR_REQUEST_DELETE,
    COMPETITOR_SUCCESS,
    COMPETITOR_ERROR,

} from '../actions/types';

const initialState = {
    currentInvestment: {loading: false, error: '', investment: null,},
    universeData : {loading: false, error: '', universe: [],},
    thematicData: { loading: false, error: '', thematics: [],},
    competitorData: { loading: false, error: '', competitors: [], },
    bigMoveData: {loading: false, error: '', bigMoves: []},
    genNoteData: {loading: false, error: '', genNotes: []},
    optionValues: {loading: false,
                          error: '',
                          optionValues: {
                                    passFailOptions:[],
                                    themeOptions:[],
                                    industryOptions:[],
                                    cyclicalOptions:[],
                                    categoryOptions:[],},},
    transScoreData: {loading: false,
                            error: '',
                            stockTransScore: {
                                        normScore:0,
                                        scores: [],
                                        transScoreList:[]},},
    passFailData: { loading: false, error: '', stockDetails: {strategies: []},},
    dueDiligenceData: { loading: false,
                                 error: '',
                                 stockDueDiligence: {dueDiligenceSteps: [],
                                                               dueDiligenceRecords:[],
                                                               summary: {
                                                                        totalDDRecordCount: 0,
                                                                        actions:[],},}},
};

export default function (state = initialState, action) {
    switch (action.type) {
        ////////////////////////////////////////
        // UNIVERSE REDUCERS
        ////////////////////////////////////////
        case FETCH_UNIVERSE_REQUEST:
            return {
                ...state,
                universeData:{
                    ...state.universeData,
                    loading: true, }
            };
        case FETCH_UNIVERSE_SUCCESS:
            return {
                ...state,
                universeData: {
                    loading: false,
                    universe : action.payload,
                }
            };
        case FETCH_UNIVERSE_ERROR:
            return {
                ...state,
                universeData: {
                    ...state.universeData,
                    loading: false,
                    error : action.payload,
                }
            };
        case REHYDRATE_UNIVERSE:
            return {
                ...state,
            };
        ////////////////////////////////////////
        // CURRENT INVESTMENT REDUCERS
        ////////////////////////////////////////
        case SET_CURRENT_INVESTMENT:
            return {
                ...state,
            };
        case SET_CURRENT_INVESTMENT_SUCCESS:
            return {
                ...state,
                currentInvestment : {
                    ...state.currentInvestment,
                    investment: action.payload
                }
            };
        case SET_CURRENT_INVESTMENT_ERROR:
            return {
                ...state,
                currentInvestment: {
                    ...state.currentInvestment,
                    error: action.payload,
                }
            };
        case REHYDRATE_CURRENT_INVESTMENT:
            return {
                ...state,
            };

        ////////////////////////////////////////
        // SELECT OPTION VALUES REDUCERS
        ////////////////////////////////////////
        case FETCH_OPTION_VALUES_REQUEST:
            return {
                ...state,
                optionValues:{
                    ...state.optionValues,
                    loading: true, }
            };
        case FETCH_OPTION_VALUES_SUCCESS:
            return {
                ...state,
                optionValues: {
                    loading: false,
                    ...action.payload,
                }
            };
        case FETCH_OPTION_VALUES_ERROR:
            return {
                ...state,
                optionValues: {
                    ...state.optionValues,
                    loading: false,
                    error : action.payload, }
            };
        ////////////////////////////////////////
        // PASSFAIL REDUCERS
        ////////////////////////////////////////
        case FETCH_PASSFAIL_REQUEST:
            return {
                ...state,
                passFailData:{
                    ...state.passFailData,
                    loading: true,
                }
            };
        case FETCH_PASSFAIL_SUCCESS:
            return {
                ...state,
                passFailData: {
                    ...state.passFailData,
                    loading: false,
                    stockDetails : action.payload,
                }
            };
        case FETCH_PASSFAIL_ERROR:
            return {
                ...state,
                passFailData: {
                    loading: false,
                    stockDetails: {},
                    error: action.payload,
                }
            };

        case UPDATE_PASSFAIL_REQUEST:
            return {
                ...state,
            };
        case UPDATE_PASSFAIL_SUCCESS:
            return {
                ...state,
                passFailData: {
                    ...state.passFailData,
                    loading: false,
                    stockDetails : action.payload,
                }
            };
        case UPDATE_PASSFAIL_ERROR:
            return {
                ...state,
                passFailData: {
                    ...state.passFailData,
                    loading: false,
                    error: action.payload,
                }
            };
        ////////////////////////////////////////
        // THEMATIC REDUCERS
        ////////////////////////////////////////
        case THEMATIC_REQUEST_FETCH:
            return {
                ...state,
                thematicData:{
                    ...state.thematicData,
                    loading: true
                }
            };
        case THEMATIC_REQUEST_ADD:
            return {
                ...state,
                thematicData:{
                    ...state.thematicData,
                }
            };
        case THEMATIC_REQUEST_UPDATE:
            return {
                ...state,
                thematicData:{
                    ...state.thematicData,
                }
            };
        case THEMATIC_REQUEST_DELETE:
            return {
                ...state,
                thematicData:{
                    ...state.thematicData,
                }
            };
        case THEMATIC_SUCCESS:
            return {
                ...state,
                thematicData: {
                    ...state.thematicData,
                    loading: false,
                    thematics : action.payload,
                }
            };
        case THEMATIC_ERROR:
            return {
                ...state,
                thematicData: {
                    ...state.thematicData,
                    loading: false,
                    error : action.payload,
                }
            };
        ////////////////////////////////////////
        // COMPETITOR REDUCERS
        ////////////////////////////////////////
        case COMPETITOR_REQUEST_FETCH:
            return {
                ...state,
                competitorData:{
                    ...state.competitorData,
                    loading: true
                }
            };
        case COMPETITOR_REQUEST_ADD:
            return {
                ...state,
                competitorData:{
                    ...state.competitorData,
                }
            };
        case COMPETITOR_REQUEST_UPDATE:
            return {
                ...state,
                competitorData:{
                    ...state.competitorData,
                }
            };
        case COMPETITOR_REQUEST_DELETE:
            return {
                ...state,
                competitorData:{
                    ...state.competitorData,
                }
            };
        case COMPETITOR_SUCCESS:
            return {
                ...state,
                competitorData: {
                    ...state.competitorData,
                    loading: false,
                    competitors : action.payload,
                }
            };
        case COMPETITOR_ERROR:
            return {
                ...state,
                competitorData: {
                    ...state.competitorData,
                    loading: false,
                    error : action.payload,
                }
            };
        ////////////////////////////////////////
        // TRANSFORMATION SCORE REDUCERS
        ////////////////////////////////////////
        case TRANSSCORE_REQUEST_FETCH:
            return {
                ...state,
                transScoreData:{
                    ...state.transScoreData,
                    loading: true
                }
            };
        case TRANSSCORE_REQUEST_ADD:
            return {
                ...state,
                transScoreData:{
                    ...state.transScoreData,
                }
            };
        case TRANSSCORE_REQUEST_UPDATE:
            return {
                ...state,
                transScoreData:{
                    ...state.transScoreData,
                }
            };
        case TRANSSCORE_REQUEST_DELETE:
            return {
                ...state,
                transScoreData:{
                    ...state.transScoreData,
                }
            };
        case TRANSSCORE_SUCCESS:
            return {
                ...state,
                transScoreData: {
                    error: '',
                    loading: false,
                    stockTransScore : action.payload,
                }
            };
        case TRANSSCORE_ERROR:
            return {
                ...state,
                transScoreData: {
                    loading: false,
                    error : action.payload,
                }
            };

        ////////////////////////////////////////
        // DUE DILIGENCE REDUCERS
        ////////////////////////////////////////
        case DUE_DILIGENCE_REQUEST_FETCH:
            return {
                ...state,
                dueDiligenceData:{
                    ...state.dueDiligenceData,
                    loading: true
                }
            };
        case DUE_DILIGENCE_REQUEST_ADD:
            return {
                ...state,
                dueDiligenceData:{
                    ...state.dueDiligenceData,
                }
            };
        case DUE_DILIGENCE_REQUEST_UPDATE:
            return {
                ...state,
                dueDiligenceData:{
                    ...state.dueDiligenceData,
                }
            };
        case DUE_DILIGENCE_REQUEST_DELETE:
            return {
                ...state,
                dueDiligenceData:{
                    ...state.dueDiligenceData,
                }
            };
        case DUE_DILIGENCE_SUCCESS:
            return {
                ...state,
                dueDiligenceData: {
                    ...state.dueDiligenceData,
                    loading: false,
                    stockDueDiligence : action.payload,
                }
            };
        case DUE_DILIGENCE_ERROR:
            return {
                ...state,
                dueDiligenceData: {
                    ...state.dueDiligenceData,
                    loading: false,
                    error : action.payload,
                }
            };

        ////////////////////////////////////////
        // BIG MOVE REDUCERS
        ////////////////////////////////////////
        case BIGMOVE_REQUEST_FETCH:
            return {
                ...state,
                bigMoveData:{
                    ...state.bigMoveData,
                    loading: true
                }
            };
        case BIGMOVE_REQUEST_ADD:
            return {
                ...state,
                bigMoveData:{
                    ...state.bigMoveData,
                }
            };
        case BIGMOVE_REQUEST_UPDATE:
            return {
                ...state,
                bigMoveData:{
                    ...state.bigMoveData,
                }
            };
        case BIGMOVE_REQUEST_DELETE:
            return {
                ...state,
                bigMoveData:{
                    ...state.bigMoveData,
                }
            };
        case BIGMOVE_SUCCESS:
            return {
                ...state,
                bigMoveData: {
                    ...state.bigMoveData,
                    loading: false,
                    bigMoves : action.payload,
                }
            };
        case BIGMOVE_ERROR:
            return {
                ...state,
                bigMoveData: {
                    ...state.bigMoveData,
                    loading: false,
                    error : action.payload,
                }
            };

        ////////////////////////////////////////
        // GEN NOTE REDUCERS
        ////////////////////////////////////////
        case GENNOTE_REQUEST_FETCH:
            return {
                ...state,
                genNoteData:{
                    ...state.genNoteData,
                    loading: true
                }
            };
        case GENNOTE_REQUEST_ADD:
            return {
                ...state,
                genNoteData:{
                    ...state.genNoteData,
                }
            };
        case GENNOTE_REQUEST_UPDATE:
            return {
                ...state,
                genNoteData:{
                    ...state.genNoteData,
                }
            };
        case GENNOTE_REQUEST_DELETE:
            return {
                ...state,
                genNoteData:{
                    ...state.genNoteData,
                }
            };
        case GENNOTE_SUCCESS:
            return {
                ...state,
                genNoteData: {
                    ...state.genNoteData,
                    loading: false,
                    genNotes : action.payload,
                }
            };
        case GENNOTE_ERROR:
            return {
                ...state,
                genNoteData: {
                    ...state.genNoteData,
                    loading: false,
                    error : action.payload,
                }
            };
        default:
            return state;
    }
}
