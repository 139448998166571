import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TokenService from '../../services/token-service';
import { connect } from 'react-redux';
import { checkUserTokenAction } from '../../actions/authActions';

function AdminRoute({ component, ...props }) {
    const Component = component;
    const useradmin = new Set(['sszeman', 'toddcd'])

    return (
        <Route
            {...props}
            render={(componentProps) =>
                TokenService.hasAuthToken() && useradmin.has(TokenService.getCurrentUser()) ? (
                    <Component {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/universe',
                            state: { from: componentProps.location },
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { checkUserTokenAction })(AdminRoute);
