export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LOGOUT_USER = 'LOGOUT_USER';
export const CHECK_USER_TOKEN = 'CHECK_USER_TOKEN';

export const OPEN_MODAL_ADD_ORG = 'OPEN_MODAL_ADD_ORG';
export const CLOSE_MODAL_ADD_ORG = 'CLOSE_MODAL_ADD_ORG';

export const REHYDRATE_UNIVERSE = 'REHYDRATE_UNIVERSE';
export const FETCH_UNIVERSE_REQUEST = 'FETCH_UNIVERSE_REQUEST';
export const FETCH_UNIVERSE_SUCCESS = 'FETCH_UNIVERSE_SUCCESS';
export const FETCH_UNIVERSE_ERROR = 'FETCH_UNIVERSE_ERROR';

export const REHYDRATE_CURRENT_INVESTMENT = 'REHYDRATE_CURRENT_INVESTMENT';
export const SET_CURRENT_INVESTMENT = 'SET_CURRENT_INVESTMENT';
export const SET_CURRENT_INVESTMENT_SUCCESS = 'SET_CURRENT_INVESTMENT_SUCCESS';
export const SET_CURRENT_INVESTMENT_ERROR = 'SET_CURRENT_INVESTMENT_ERROR';

export const FETCH_OPTION_VALUES_REQUEST = 'FETCH_OPTION_VALUES_REQUEST';
export const FETCH_OPTION_VALUES_SUCCESS = 'FETCH_OPTION_VALUES_SUCCESS';
export const FETCH_OPTION_VALUES_ERROR = 'FETCH_OPTION_VALUES_ERROR';

export const FETCH_PASSFAIL_REQUEST = 'FETCH_PASSFAIL_REQUEST';
export const FETCH_PASSFAIL_SUCCESS = 'FETCH_PASSFAIL_SUCCESS';
export const FETCH_PASSFAIL_ERROR = 'FETCH_PASSFAIL_ERROR';
export const UPDATE_PASSFAIL_REQUEST = 'UPDATE_PASSFAIL_REQUEST';
export const UPDATE_PASSFAIL_SUCCESS = 'UPDATE_PASSFAIL_SUCCESS';
export const UPDATE_PASSFAIL_ERROR = 'UPDATE_PASSFAIL_ERROR';

export const TRANSSCORE_REQUEST_FETCH = 'TRANSSCORE_REQUEST_FETCH';
export const TRANSSCORE_REQUEST_ADD = 'TRANSSCORE_REQUEST_ADD';
export const TRANSSCORE_REQUEST_UPDATE = 'TRANSSCORE_REQUEST_UPDATE';
export const TRANSSCORE_REQUEST_DELETE = 'TRANSSCORE_REQUEST_DELETE';
export const TRANSSCORE_SUCCESS = 'TRANSSCORE_SUCCESS';
export const TRANSSCORE_ERROR = 'TRANSSCORE_ERROR';

export const DUE_DILIGENCE_REQUEST_FETCH = 'DUE_DILIGENCE_REQUEST_FETCH';
export const DUE_DILIGENCE_REQUEST_ADD = 'DUE_DILIGENCE_REQUEST_ADD';
export const DUE_DILIGENCE_REQUEST_UPDATE = 'DUE_DILIGENCE_REQUEST_UPDATE';
export const DUE_DILIGENCE_REQUEST_DELETE = 'DUE_DILIGENCE_REQUEST_DELETE';
export const DUE_DILIGENCE_SUCCESS = 'DUE_DILIGENCE_SUCCESS';
export const DUE_DILIGENCE_ERROR = 'DUE_DILIGENCE_ERROR';

export const BIGMOVE_REQUEST_FETCH = 'BIGMOVE_REQUEST_FETCH';
export const BIGMOVE_REQUEST_ADD = 'BIGMOVE_REQUEST_ADD';
export const BIGMOVE_REQUEST_UPDATE = 'BIGMOVE_REQUEST_UPDATE';
export const BIGMOVE_REQUEST_DELETE = 'BIGMOVE_REQUEST_DELETE';
export const BIGMOVE_SUCCESS = 'BIGMOVE_SUCCESS';
export const BIGMOVE_ERROR = 'BIGMOVE_ERROR';

export const GENNOTE_REQUEST_FETCH = 'GENNOTE_REQUEST_FETCH';
export const GENNOTE_REQUEST_ADD = 'GENNOTE_REQUEST_ADD';
export const GENNOTE_REQUEST_UPDATE = 'GENNOTE_REQUEST_UPDATE';
export const GENNOTE_REQUEST_DELETE = 'GENNOTE_REQUEST_DELETE';
export const GENNOTE_SUCCESS = 'GENNOTE_SUCCESS';
export const GENNOTE_ERROR = 'GENNOTE_ERROR';

export const THEMATIC_REQUEST_FETCH = 'THEMATIC_REQUEST_FETCH';
export const THEMATIC_REQUEST_ADD = 'THEMATIC_REQUEST_ADD';
export const THEMATIC_REQUEST_UPDATE = 'THEMATIC_REQUEST_UPDATE';
export const THEMATIC_REQUEST_DELETE = 'THEMATIC_REQUEST_DELETE';
export const THEMATIC_SUCCESS = 'THEMATIC_SUCCESS';
export const THEMATIC_ERROR = 'THEMATIC_ERROR';

export const COMPETITOR_REQUEST_FETCH = 'FETCH_COMPETITOR_REQUEST';
export const COMPETITOR_REQUEST_ADD = 'FETCH_COMPETITOR_ADD';
export const COMPETITOR_REQUEST_UPDATE = 'FETCH_COMPETITOR_UPDATE';
export const COMPETITOR_REQUEST_DELETE = 'FETCH_COMPETITOR_DELETE';
export const COMPETITOR_SUCCESS = 'FETCH_COMPETITOR_SUCCESS';
export const COMPETITOR_ERROR = 'FETCH_COMPETITOR_ERROR';