import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import TokenService from "../../../../../services/token-service";
import AddEditToolBar from "../../../common/AddEditToolBar";
import TD3FormikSelectField from "../../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField";
import {connect} from "react-redux";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #6d6d6d',
    },
    numberField: {
        flex: 1,
        margin: 1,
        '& .MuiInputBase-input::-webkit-outer-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input::-webkit-inner-spin-button' : {'-webkit-appearance': 'none'},
        '& .MuiInputBase-input[type=number]' : {'-moz-appearance': 'textfield'},
    }
}));

function AddEditInvGenNote(props) {
    const classes = useStyles();
    const {
        idkDD_stock,
        enteredby,
        editedby,
        DD_Status,
        DD_Conviction,
        DD_Rank,
        DD_HyperLink,
        idkDD_Memo,
        Memo
    } = {
        ...props.initialValues,
    };

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
            props.handleDelete({idkDD_stock: values.idkDD_stock,
                idkInvestment: values.idkInvestment});
            props.handleCancel();
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: props.investment,
                    idkDD_stock: idkDD_stock ? idkDD_stock : '',
                    idkDD_Action: 51,
                    DD_Status: DD_Status ? DD_Status : 'medium',
                    DD_Conviction: DD_Conviction ? DD_Conviction : 5,
                    DD_Rank: DD_Rank ? DD_Rank : 5,
                    DD_HyperLink: DD_HyperLink ? DD_HyperLink : '',
                    idkDD_Memo: idkDD_Memo ? idkDD_Memo : '',
                    Memo: Memo ? Memo : '',
                    enteredby: enteredby ? enteredby : TokenService.getCurrentUser(),
                    editedby: editedby ? editedby : TokenService.getCurrentUser(),
                }}
                onSubmit={props.onSubmit}
            >
                {({ values, errors, dirty }) => (
                    <Form>
                        <AddEditToolBar
                            mobile={props.mobile}
                            combined={true}
                            handleCancel={props.handleCancel}
                            handleDelete={handleDeleteConfirmed}
                            saveDisabled={!dirty}
                            cancelDisabled={!dirty}
                            deleteDisabled={!values.idkDD_stock}
                            setShowAddEdit={props.setShowAddEdit}
                            values={values}
                        />
                        <div style={{display: 'flex', marginTop: 10}}>
                            <TD3FormikSelectField
                                name="DD_Status"
                                label="Importance"
                                margin="normal"
                                style={{ flex: 1, margin: 1 }}
                                variant="outlined"
                                options={[
                                    { label: '', value: '' },
                                    { label: 'High', value: 'high' },
                                    {label: 'Medium', value: 'medium',},
                                    {label: 'Low',  value: 'low',},
                                ]}
                            />
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Conviction"
                                label="Conviction"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Conviction'/>}
                                as={TextField}
                            />
                            <Field
                                className={classes.numberField}
                                type="number"
                                name="DD_Rank"
                                label="Rank"
                                variant="outlined"
                                helperText={<ErrorMessage name='DD_Rank'/>}
                                as={TextField}
                            />
                        </div>
                        <div className={classes.selectGroup}>
                            <Field
                                style={{ flex: 1, margin: 1 }}
                                type="input"
                                name="DD_HyperLink"
                                label="Link"
                                variant="outlined"
                                value={values.DD_HyperLink}
                                as={TextField}
                            />
                        </div>
                        <div style={{ padding: 5 }}>
                            <Field
                                style={{ width: '100%' }}
                                type="input"
                                name="Memo"
                                variant="outlined"
                                label="Note"
                                multiline
                                rows={11}
                                value={values.Memo}
                                as={TextField}
                            />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapStateToProps = (state) => ({
    investment: state.research.currentInvestment.investment,
});

export default connect(mapStateToProps, {})(AddEditInvGenNote);

