import React, {  } from 'react';
import LoginForm from '../../components/LoginForm/LoginFormSide';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'center',
        'alignItems': 'center',
        'height': '100vh',
        'width': '100%',
    },
}));

function LoginPage (){
    const classes = useStyles();
        return  (
            <div className={classes.root}>
                <LoginForm />
            </div>
        );
}

export default LoginPage;
