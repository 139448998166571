import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { Field, Form, Formik } from 'formik';
import AddEditToolBar from "../../../common/AddEditToolBar";
import {connect} from "react-redux";
import CompetitorAutoComplete from "./CompetitorAutoComplete";

const useStyles = makeStyles((theme) => ({
    selectGroup: {
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        padding: 4,
    },
    toolBar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderBottom: '1px solid #6d6d6d',
    },
}));

function AddEditCompetitor(props) {
    const classes = useStyles();
    const {  idk_stock_competitors,
                Competitor_Name,
                compIdkInvestment,
                Competitor_Ticker,
                note } = {...props.initialValues,};

    const handleDeleteConfirmed = (e, values) =>{
        if(e.target.textContent === 'Yes'){
                props.handleDelete({idk_stock_competitors: values.idk_stock_competitors,
                idkInvestment: values.idkInvestment});
                props.handleCancel();
        }
    };

    return (
        <div>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    idkInvestment: props.investment,
                    idk_stock_competitors: idk_stock_competitors ? idk_stock_competitors : 0,
                    Competitor_Ticker: Competitor_Ticker ? Competitor_Ticker : '',
                    compIdkInvestment : compIdkInvestment ? compIdkInvestment : '',
                    Competitor_Name: Competitor_Name ? Competitor_Name : '',
                    note: note ? note : '',
                }}
                onSubmit={props.onSubmit}
            >
                {({ values, errors, dirty, touched,setFieldValue }) => (
                    <Form>
                        <AddEditToolBar
                            mobile={props.mobile}
                            combined={true}
                            handleCancel={props.handleCancel}
                            handleDelete={handleDeleteConfirmed}
                            saveDisabled={!dirty}
                            cancelDisabled={!dirty}
                            deleteDisabled={values.idk_stock_competitors === 0}
                            setShowAddEdit={props.setShowAddEdit}
                            values={values}
                        />
                        <div className={classes.selectGroup}>
                                <CompetitorAutoComplete
                                    style={{ flex: 1, padding: 5 }}
                                    disabled={values.idk_stock_competitors > 0 }
                                    touched={touched}
                                    options={props.universe ? props.universe.filter(comp => comp.name !== null) : []}
                                    name="compName"
                                    setFieldValue={setFieldValue}
                                    value={values.Competitor_Name}
                                    inputValue={values.Competitor_Name}
                                />
                                <Field
                                    style={{ flex: 1, padding: 5 }}
                                    type="input"
                                    disabled={true}
                                    name="Competitor_Ticker"
                                    variant="outlined"
                                    label="Competitor Ticker"
                                    as={TextField}
                                />

                        </div>

                        <div style={{ padding: 5 }}>
                            <Field
                                style={{ width: '100%' }}
                                type="input"
                                name="note"
                                variant="outlined"
                                label="Note"
                                multiline
                                rows={11}
                                value={values.note}
                                as={TextField}
                            />
                        </div>
                        {/*<pre>{JSON.stringify(values, null, 2)}</pre>*/}
                    </Form>
                )}
            </Formik>
        </div>
    );
}

const mapStateToProps = (state) => ({
    investment: state.research.currentInvestment.investment,
    optionValues: state.research.optionValues,
    universe: state.research.universeData.universe,
});

export default connect(mapStateToProps, {})(AddEditCompetitor);
