import React, {Fragment, useEffect} from 'react';
import { withRouter } from 'react-router-dom';
import PrimarySearchAppBar from '../AppBar/PrimarySearchAppBar';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Name from './Summary/Name';
import TransformationalScoreSummary from './Summary/TransformationalScoreSummary';
import InvestmentDetailTabs from './Detail/InvestmentDetailTabs';
import Paper from '@material-ui/core/Paper';
import {connect} from "react-redux";
import {
    fetchCompetitor,
    fetchDueDiligence,
    fetchOptionValues,
    fetchPassFail,
    fetchThematic,
    fetchTransScore,
    fetchBigMove,
    fetchGenNote,
    setCurrentInvestment,
    rehydrateUniverse
} from "../../actions/researchActions";
import DueDiligenceSummary from "./Summary/DueDiligenceSummary";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop: 8,
        marginBottom: 8
    },
    paper: {
        margin: theme.spacing(0.5),
        padding: theme.spacing(1),
        textAlign: 'center',
        height: '100%',
        color: theme.palette.text.secondary,
        minWidth: 340,
    },
    loading: {height: '100vh',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'}
}));

const InvestmentDetail = ({loading, error,
                          rehydrateUniverse,
                          setCurrentInvestment,
                          fetchOptionValues,
                          fetchPassFail,
                          fetchThematic,
                          fetchCompetitor,
                          fetchTransScore,
                          fetchDueDiligence,
                          fetchBigMove,
                          fetchGenNote,
                          location, match}) => {
    const classes = useStyles();
    const { params } = match;

    const idkInvestment = params.idkInvestment;

    useEffect(() => {
        // set current investment research data
        const fetchData = async () =>{
            await  fetchTransScore(idkInvestment)
            await setCurrentInvestment(idkInvestment);
            await rehydrateUniverse();

            // get options for select fields
            fetchOptionValues();
            fetchPassFail(idkInvestment);
            fetchThematic(idkInvestment);
            fetchCompetitor(idkInvestment);
            fetchDueDiligence(idkInvestment);
            fetchBigMove(idkInvestment);
            fetchGenNote(idkInvestment);
        }

        fetchData();

    }, [idkInvestment, fetchOptionValues, fetchPassFail,
        rehydrateUniverse, setCurrentInvestment, fetchThematic,
        fetchCompetitor, fetchTransScore, fetchDueDiligence,
        fetchBigMove, fetchGenNote]);

    return (<Fragment>
            <PrimarySearchAppBar />
            {loading ? (
                <div className={classes.loading}><CircularProgress/></div>
            ) : error ? (<div> {error}</div>) :
                <Fragment>
                <div className={classes.root}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className={classes.paper}>
                                <Name stock={location.state} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className={classes.paper}>
                                <TransformationalScoreSummary
                                    stock={location.state}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} sm={6} md={3}>
                            <Paper className={classes.paper}>
                                <DueDiligenceSummary stock={location.state}/>
                            </Paper>
                        </Grid>
                        {/*<Grid item xs={12} sm={6} md={3}>*/}
                        {/*    <Paper className={classes.paper}>*/}
                        {/*        <DueDiligenceSummary stock={location.state}/>*/}
                        {/*    </Paper>*/}
                        {/*</Grid>*/}
                    </Grid>
                </div>
                <InvestmentDetailTabs/>
                </Fragment>
            }
        </Fragment>
    );
};

const mapStateToProps = (state) => ({
    loading: state.research.transScoreData.loading,
    error: state.research.transScoreData.error,
    investment: state.research.currentInvestment.investment,
});

const mapDispatchToProps = (dispatch) => {
    return {
        rehydrateUniverse: () => dispatch(rehydrateUniverse()),
        fetchOptionValues: () => dispatch(fetchOptionValues()),
        setCurrentInvestment: (idkInvestment) => dispatch(setCurrentInvestment(idkInvestment)),
        fetchPassFail: (idkInvestment) => dispatch(fetchPassFail(idkInvestment)),
        fetchThematic: (idkInvestment) => dispatch(fetchThematic(idkInvestment)),
        fetchCompetitor: (idkInvestment) => dispatch(fetchCompetitor(idkInvestment)),
        fetchTransScore: (idkInvestment) => dispatch(fetchTransScore(idkInvestment)),
        fetchDueDiligence: (idkInvestment) => dispatch(fetchDueDiligence(idkInvestment)),
        fetchBigMove: (idkInvestment) => dispatch(fetchBigMove(idkInvestment)),
        fetchGenNote: (idkInvestment) => dispatch(fetchGenNote(idkInvestment))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InvestmentDetail));
