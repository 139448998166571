import React from 'react';
import Chart from 'chart.js';

const plugin = {
    afterDraw: function (chart) {
        if (chart.config.options.elements.center) {
            let helpers = Chart.helpers;
            let centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            let centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            let ctx = chart.chart.ctx;
            ctx.save();
            let fontSize = helpers.getValueOrDefault(chart.config.options.elements.center.fontSize,
                Chart.defaults.global.defaultFontSize);
            let fontStyle = helpers.getValueOrDefault(chart.config.options.elements.center.fontStyle,
                Chart.defaults.global.defaultFontStyle);
            let fontFamily = helpers.getValueOrDefault(chart.config.options.elements.center.fontFamily,
                Chart.defaults.global.defaultFontFamily);
            let font = helpers.fontString(fontSize, fontStyle, fontFamily);
            ctx.font = font;
            ctx.fillStyle = helpers.getValueOrDefault(chart.config.options.elements.center.fontColor,
                Chart.defaults.global.defaultFontColor);
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(chart.config.options.elements.center.text, centerX, centerY);
            ctx.restore();
        }
    }
}

class DueDiligenceDoughnutChart extends React.Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    componentDidUpdate() {

        this.myChart.data.labels = this.props.data.map(d => d.label);
        //this.myChart.data.colors = color;
        this.myChart.data.datasets[0].data = this.props.data.map(d => d.percent);
        this.myChart.options.elements.center.text = this.props.totalCount;
        this.myChart.update();
    }

    componentDidMount() {
        this.myChart = new Chart(this.canvasRef.current, {
            plugins: [plugin],
            type: 'doughnut',
            options: {
                responsive: true,
                legend: {
                    display: false,
                },
                cutoutPercentage: 50,
                elements: {
                    center: {
                        text: this.props.totalCount,
                        fontColor: '#fff',
                        fontFamily: "'Helvetica Neue', 'Helvetica', 'Arial', sans-serif",
                        fontSize: 36,
                        fontStyle: 'bold'
                    }
                }
            },
            data: {
                labels: this.props.data.map(d => d.label),
                datasets: [
                    {
                        backgroundColor: ["#66bb6a","#a0a34b","#e57373"],
                        data: this.props.data.map(d => d.percent),
                    },
                ],
            }
        })
    }

    render() {
        return  <div>
                    <canvas ref={this.canvasRef}/>
                </div>
    }
}

export default DueDiligenceDoughnutChart;