import React, {Fragment} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutAction } from '../../actions/authActions';
import { openAddOrgModal } from '../../actions/modalActions';
import TokenService from "../../services/token-service";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const SideDrawer = (props) => {
    const classes = useStyles();
    //const [open, setOpen] = React.useState(true);

    // const handleClick = () => {
    //     setOpen(!open);
    // };

    const handleClickRedirect = (event, add) => {
        let pathname = '/' + event.currentTarget.id;
        props.history.push({ pathname: pathname });
    };

    // const handleOpenModal = (event) => {
    //     props.openAddOrgModal();
    // };

    const admins = new Set(['sszeman', 'toddcd'])

    const handleLogout = () => {
        props.logoutAction();
        props.history.push('/');
    };

    const sideList = (side) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggle}
            onKeyDown={props.toggle}
        >
            <List>
                <ListItem
                    button
                    key={'universe'}
                    id={'universe'}
                    onClick={handleClickRedirect}
                >
                    <ListItemText primary="Investment Universe" />
                </ListItem>
                <Divider />
                <ListItem
                    button
                    key={'logout'}
                    id={'logout'}
                    onClick={handleLogout}
                >
                    <ListItemText primary="Logout" />
                </ListItem>
                <ListItem
                    button
                    key={'changepassword'}
                    id={'changepassword'}
                    onClick={handleClickRedirect}
                >
                    <ListItemText primary="Change Password" />
                </ListItem>
                {admins.has(TokenService.getCurrentUser()) ?
                    <Fragment>
                        <Divider />
                        <ListItem>
                            <ListItemText primary="Administration" />
                        </ListItem>
                        <div style={{marginLeft: 30}}>
                        <ListItem
                            button
                            key={'useradmin/create'}
                            id={'useradmin/create'}
                            onClick={handleClickRedirect}
                        >
                            <ListItemText primary="Create New User" />
                        </ListItem>
                        <ListItem
                            button
                            key={'useradmin/update'}
                            id={'useradmin/update'}
                            onClick={handleClickRedirect}
                        >
                            <ListItemText primary="Change User Password" />
                        </ListItem>
                        </div>
                    </Fragment>
                : <div/>}
            </List>
        </div>
    );

    return (
        <div>
            <SwipeableDrawer
                open={props.open.left}
                onClose={props.toggle}
                onOpen={props.toggle}
            >
                {sideList('left')}
            </SwipeableDrawer>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    openModal: state.openModal,
});

//export default withRouter(SideDrawer)
export default connect(mapStateToProps, { logoutAction, openAddOrgModal })(
    withRouter(SideDrawer)
);
