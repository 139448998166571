import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TokenService from '../../services/token-service';
import { connect } from 'react-redux';
import { checkUserTokenAction } from '../../actions/authActions';

function PrivateRoute({ component, ...props }) {
    const Component = component;

    return (
        <Route
            {...props}
            render={(componentProps) =>
                TokenService.hasAuthToken() ? (
                    <Component {...componentProps} />
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: componentProps.location },
                        }}
                    />
                )
            }
        />
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { checkUserTokenAction })(PrivateRoute);
