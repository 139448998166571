import React, {Fragment} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import ChangePasswordForm from "../../components/LoginForm/ChangePasswordForm";
import PrimarySearchAppBar from "../../components/AppBar/PrimarySearchAppBar";

const useStyles = makeStyles((theme) => ({
    root: {
        'display': 'flex',
        'flexDirection': 'column',
        'justifyContent': 'center',
        'alignItems': 'center',
        'height': '100vh',
        'width': '100%',
    },
}));

function ChangePasswordPage (){
    const classes = useStyles();
        return  (
            <Fragment>
                <PrimarySearchAppBar />
                <div style={{display: "flex", alignItems: 'center', flexDirection: "column", justifyContent: "center", textAlign: 'center'}}>
                    <h2>Change Your Password</h2>
                    <ChangePasswordForm/>
                </div>
            </Fragment>
        );
}

export default ChangePasswordPage;
