import React from 'react';
// import { Doughnut } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import DueDiligenceDoughnutChart from "./DueDiligenceDoughnutChart";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
    chart: {
        width: '90%',
        height: '90%',
        zIndex: 1,
    },
    paper: {
        padding: 2,
        color: theme.palette.primary,
    },
    color: theme.palette.primary,
}));

const DueDiligenceChartContainer = ({transScoreData,
                                                       stockDueDiligence}) => {
    const classes = useStyles();
    const summary = stockDueDiligence ? stockDueDiligence.summary : {actions: []};
    const actionMap = {Completed: 0,
                                InProgress: 0,
                                OutOfDate: 0}
    const data = []

    summary.actions.forEach((action) =>{
        const actionKey = action.actionStatus;
        actionMap[actionKey] = actionMap[actionKey] + 1 || 1;
    });

    for(let action in actionMap){
        const percent = actionMap[action] / summary.actions.length
        const dataPoint = {
            percent: percent,
            label: action
        }
        data.push(dataPoint)
    }

    return transScoreData.loading ? (
        <div className={classes.loading}><CircularProgress /></div>
    ) : transScoreData.error ? (
        <div>{transScoreData.error}</div>
    ) :  (
            <div id="chart-wrapper" className={classes.chart}>
                {summary.totalDDRecordCount ?
                    <DueDiligenceDoughnutChart data={data}
                                               totalCount={summary.totalDDRecordCount}/>
                    : <Typography variant='body1'>Compulsory Due Diligence not started.</Typography>}
            </div>
    );
};

const mapStateToProps = (state) => ({
    transScoreData: state.research.transScoreData,
    stockDueDiligence: state.research.dueDiligenceData.stockDueDiligence
});

export default connect(mapStateToProps, {})(DueDiligenceChartContainer);