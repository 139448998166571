import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import ListItem from "@material-ui/core/ListItem";
import {format} from "date-fns";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    rotateIcon45: {
        webkitTransform: 'rotate(180deg)',
        // moz-transform: rotate(45deg);
        // -ms-transform: rotate(45deg);
        // -o-transform: rotate(45deg);
        transform: 'rotate(270deg)',
    },
});

function Row(props) {
    const { row, selectedIdkDD, setSelectedIdkDD, handleAddEditClicked, mobile } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow  onClick={(e) =>{
                if(mobile){
                    setOpen(!open)
                }else {
                    handleAddEditClicked(e)
                    setSelectedIdkDD(row.idkDD_stock)
                }
            }}
                               data-id={row.idkDD_stock}
                               selected={selectedIdkDD === row.idkDD_stock}
                               hover={true} className={classes.root}>
                    {mobile ?
                    <TableCell padding={mobile ? "none": "default"}>
                        <IconButton   aria-label="expand row" size="medium" >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell> : null}
                    <TableCell padding={mobile ? "none": "default"}
                                    onClick={() => setOpen(!open)} >{row.enteredby}
                    </TableCell>
                    <TableCell padding={mobile ? "none": "default"}
                                    onClick={() => setOpen(!open)} >{row.DD_Status}
                    </TableCell>
                    <TableCell padding={mobile ? "none": "default"}
                                    onClick={() => setOpen(!open)} >{row.DD_Value}
                    </TableCell>
                    <TableCell padding={mobile ? "none": "default"}
                               onClick={() => setOpen(!open)} >{format(new Date(row.editeddate), 'yyyy-MM-dd')}
                    </TableCell>
                    {mobile ?
                        <TableCell align='right'>
                            <IconButton aria-label="edit" onClick={handleAddEditClicked} >
                                <EditIcon  style={{fontSize: 'medium'}}/>
                            </IconButton>
                        </TableCell>
                    :
                        <TableCell align='right' >
                            {selectedIdkDD === row.idkDD_stock ? <KeyboardArrowUpIcon className={classes.rotateIcon45}/>
                                : <KeyboardArrowDownIcon className={classes.rotateIcon45} />}
                         </TableCell>
                    }
            </TableRow>
                    {mobile ?
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={open} timeout="auto" unmountOnExit>
                                <Box margin={2}>
                                    <Typography style={{paddingLeft: 5, paddingRight: 5}} variant='body2'>
                                        {row.Memo}
                                    </Typography>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow> : null}
        </React.Fragment>
    );
}

Row.propTypes = {

};

export default function DueDiligenceCollapsibleTable(props) {
    return (
        <Fragment>
        <TableContainer component={Paper}>
            <Table stickyHeader size={props.mobile ? "small" : "medium"} aria-label="due diligence table">
                <TableHead >
                    <TableRow >
                        {props.mobile ? <TableCell padding='none'/> : null}
                        <TableCell style={{border: "none"}} padding={props.mobile ? "none": "default"}>Analyst</TableCell>
                        <TableCell style={{border: "none"}} padding={props.mobile ? "none": "default"}>Status</TableCell>
                        <TableCell style={{border: "none"}} padding={props.mobile ? "none": "default"}>Value</TableCell>
                        <TableCell style={{border: "none"}} padding={props.mobile ? "none": "default"}>Last Updated</TableCell>
                        <TableCell style={{border:"none", padding: '0 5px 0 0'}} align='right'>
                            <IconButton
                                    style={{fontSize: "medium"}}
                                    disabled={props.showAddEdit}
                                    onClick={props.handleAddEditClicked}
                                    aria-label="add"
                                >
                                    <AddIcon />
                                </IconButton>
                        </TableCell>
                          </TableRow>
                </TableHead>
                {props.dueDiligenceRecords.length > 0 ?
                    <TableBody>
                        {props.dueDiligenceRecords.map((row) => {
                            if(props.ddAction.idkDD_Action === row.idkDD_Action){
                                return (<Row key={row.idkDD_stock}
                                             mobile={props.mobile}
                                             row={row}
                                             selectedIdkDD={props.selectedIdkDD}
                                             setSelectedIdkDD={props.setSelectedIdkDD}
                                             handleAddEditClicked={props.handleAddEditClicked}/>
                                )}}
                        )}
                    </TableBody> :  null
                }
            </Table>
            </TableContainer>
            {props.dueDiligenceRecords.filter(row => props.ddAction.idkDD_Action === row.idkDD_Action)  < 1 ?
                <Typography variant={"body1"}
                            style={{padding: 10, textAlign: "center"}}>
                    No Due Diligence Data for Selected Step
                </Typography> : null
            }
            </Fragment>
    );
}
