export default {
  API_ENDPOINT: process.env.REACT_APP_API_ENDPOINT,
  //CALC_ENGINE_ENDPOINT: 'http://localhost:8000/api/riskbudget',
  //CALC_ENGINE_ENDPOINT: 'https://mighty-reef-59398.herokuapp.com/api/riskbudget',
  //API_ENDPOINT: 'https://damp-beach-84980.herokuapp.com/api',
  TOKEN_KEY: 'evolytical-auth-token',
  
  //Alpha Vantage Quote Service
  ALPHA_QUOTE_ENDPOINT: 'https://www.alphavantage.co/query?function=TIME_SERIES_INTRADAY&interval=5min&symbol=',
  ALPHA_SEARCH_ENDPOINT: 'https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=',
  ALPHA_KEY: 'AU6RDC2J17ZLZDZA'
  //ALPHA_KEY: 'L88V2BXCH4V0U3OA'
}