import React, {Fragment} from 'react';
import { Autocomplete } from 'formik-material-ui-lab';
import TextField from '@material-ui/core/TextField/TextField';
import InfoIcon from "@material-ui/icons/Info";
import { makeStyles } from '@material-ui/core/styles';
import {Field} from "formik";

const useStyles = makeStyles((theme) => ({
    autoComplete: {
        width: '100%',
    },
    ddStatus: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '0 5px 0 0',
    },
    universe: {
        color: '#66bb6a',
        fontSize: 'medium',
    },
    notUniverse: {
        color: '#6d6d6d',
        fontSize: 'medium',
    },
}));

CompetitorAutoComplete.propTypes = {};

function CompetitorAutoComplete(props) {
    const classes = useStyles();

    const setSelectedCompetitor = (value, setFieldValue) => {
        setFieldValue('compIdkInvestment', value ? value.idkInvestment : '')
        setFieldValue('Competitor_Ticker', value ? value.ticker : '')
        setFieldValue('Competitor_Name', value ? value.name : '')
    }

    function addUniverseIcon(universe) {
        if (!universe) {
            return (
                <span className={classes.ddStatus}>
                    <InfoIcon className={classes.notUniverse} />
                </span>
            );
        }
        return (
            <span className={classes.ddStatus}>
                    <InfoIcon className={classes.universe} />
                </span>
        );
    }

    return (
        <Fragment>
            <Field
                disabled={props.disabled}
                name={props.name}
                value={props.value}
                component={Autocomplete}
                options={props.options}
                getOptionLabel={option => {
                    if(typeof option !== 'object') {
                        return option
                    }
                    return option.name;
                }}
                style={{ width: '60%' }}
                renderOption={(option) => (
                    <React.Fragment>
                        <span>{addUniverseIcon(option.universe)}</span>
                        {option.name}
                    </React.Fragment>
                )}
                // getOptionSelected={((option, value) => {
                //     option.name === value.name
                // })}
                onChange={(event, newValue) => {
                    setSelectedCompetitor(newValue, props.setFieldValue);
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        // error={props.touched['name'] && !!props.errors['name']}
                        // helperText={props.errors['name']}
                        label="Select Competitor"
                        variant="outlined"
                    />  )}
            />
        </Fragment>
    );
}

export default CompetitorAutoComplete;
