import React, { Fragment } from 'react';
import {connect} from "react-redux";
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import Collapse from '@material-ui/core/Collapse/Collapse';
import List from '@material-ui/core/List';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import {Field} from "formik";
import {TextField} from "@material-ui/core";
import TD3FormikSelectField from "../../../MuiFormikComponents/Autocomplete/TD3FormikSelectField";
import IconButton from '@material-ui/core/IconButton';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import Tooltip from "@material-ui/core/Tooltip";


// const useStyles = makeStyles((theme) => ({
//
// }));

const ListItemStrategy = (props) => {
    //const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Fragment>
            <ListItem button disableGutters data-id={props.key} >
                <div>{open ? <ExpandLess /> : <ExpandMore />}</div>
                <ListItemText onClick={handleClick} primary={props.strategy.Source} style={{cursor: "pointer"}} />
                <TD3FormikSelectField
                    style={{ width: '40%'}}
                    name={props.idkPassFail ? props.idkPassFail : ''}
                    label="Pass/Fail"
                    margin="normal"
                    variant="outlined"
                    options={props.optionValues.passFailOptions ?
                        props.optionValues.passFailOptions.map(opt =>{
                            return {label: opt.passFailName, value: opt.idkPassFail}
                        }) : []}
                />
            </ListItem>
            {props.pfRational || props.pfRational !== '' ? (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <IconButton onClick={() =>
                            props.syncStrategyDetails({'idkPassFail': props.strategy.idkPassFail,
                                                                   'pfRational':props.strategy.pfRational},
                                                                    props.setFieldValue)}
                                    disabled={!props.dirty} edge="end">
                            <Tooltip title="Sync Detail Changes" aria-label="sync" placement="left">
                                 <FileCopyIcon style={{fontSize: "smaller"}}/>
                            </Tooltip>
                        </IconButton>
                    </div>
                    <List component="div" disablePadding>
                        <ListItem disableGutters >
                            <Field
                                style={{width: '100%',}}
                                type="input"
                                name={props.pfRational}
                                variant="outlined"
                                label="Rational"
                                multiline
                                rows={7}
                                value={props.strategy.pfRational}
                                as={TextField}
                            />

                        </ListItem>
                    </List>
                </Collapse>
            ) : (
                <div></div>
            )}

            {/*<Divider style={{listStyle: 'none'}} variant="middle" component="li" />*/}
        </Fragment>
    );
};

ListItemStrategy.propTypes = {
    // strategyRecord: PropTypes.shape({
    //     'idk_strategy_qualifier': PropTypes.number,
    //     'idkPassFail': PropTypes.number,
    //     'pfRational': PropTypes.string,
    // })
};

const mapStateToProps = (state) => ({
    optionValues: state.research.optionValues,
});

export default connect(mapStateToProps, {})(ListItemStrategy);

