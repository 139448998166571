import {
    LOGIN_USER,
    LOGIN_ERROR,
    LOGOUT_USER,
} from '../actions/types';
import AuthService from '../services/auth-api-service';
import TokenService from '../services/token-service';

export const loginAction = (user) => (dispatch) => {
    AuthService.postLogin(user)
        .then((res) => {
            console.log('User ' + user.user_name + ' has been authenticated.');

            TokenService.saveAuthToken(res.authToken);
            window.sessionStorage.setItem('currentUserValue', user.user_name);
            // Todo: add api to get a users roles from the database after
            // they have been authenticated

            dispatch({
                type: LOGIN_USER,
                payload: {
                    loggedin: true,
                    user_name: user.user_name,
                },
            });
        })
        .catch((res) => {
            console.log('Something went wrong: '+res.error);
            dispatch({
                type: LOGIN_ERROR,
                payload: {
                    error: res.error,
                },
            });
        });
};

export const logoutAction = () => (dispatch) => {

    TokenService.clearAuthToken();
    window.sessionStorage.removeItem('currentUserValue');
    window.sessionStorage.removeItem('currentInvestment');
    window.sessionStorage.removeItem('universeData');

    dispatch({
        type: LOGOUT_USER,
        payload: {
            loggedin: false,
            user_name: '',
            roles: [],
        },
    });
};

export const checkUserTokenAction = () => (dispatch) => {
    const hasToken = TokenService.hasAuthToken();
    let user = hasToken ? TokenService.decodeToken() : { sub: '' };
    console.log(user);

    dispatch({
        type: LOGOUT_USER,
        payload: {
            loggedin: hasToken,
            user_name: user,
            roles: [],
        },
    });
};
