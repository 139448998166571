import React, {Component} from 'react'
import AuthApiService from '../../services/auth-api-service'
import {Button, TextField, Box} from "@material-ui/core";

export default class ChangeUserForm extends Component {
    state = {error: null, success: null}
    // static defaultProps = {
    //     onRegistrationSuccess: () => {
    //     }
    // }

    onUpdateSuccess = (message) =>{
        this.setState({error: null, success: message})
    }

    handleSubmit = ev => {
        ev.preventDefault()
        const {user_name, password,} = ev.target

        this.setState({error: null, success: null})

        AuthApiService.updateUser({
            user_name: user_name.value,
            password: password.value,
        })
            .then(user => {
                user_name.value = ''
                password.value = ''
                this.onUpdateSuccess(user)
            })
            .catch(res => {
                this.setState({error: res.error})
            })
    }

    handleOnChange = () =>{
        this.setState({success: null})
    }

    render() {
        const {error} = this.state
        const {success} = this.state
        return (
            <div className='registration-container'>
                <form
                    className='registration-form'
                    onSubmit={this.handleSubmit}
                >
                    <div role='alert'>
                        {success && <p style={{color: 'green', fontWeight: 'bold'}} >{success}</p>}
                    </div>
                    <div role='alert'>
                        {error && <p style={{color: 'red'}}>{error}</p>}
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            //fullWidth
                            id="UpdateForm__user_name"
                            label="User Name"
                            name="user_name"
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="UpdateForm__password"
                            label="Password"
                            name="password"
                            type='password'
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}} m={5}>
                        <Button type="submit"
                                variant="contained"
                                color="primary"
                            // className={classes.submit}
                        >
                            Save
                        </Button>
                    </Box>
                </form>
            </div>
        )
    }
}
