import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TransScoreChart from './Charts/TransScoreChartContainer';
import {connect} from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

const useStyles = makeStyles((theme) => ({
    card: {
        flex: 1,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

const TransformationalScoreSummary = ({transScoreData}) => {
    const classes = useStyles();

    return transScoreData.loading ? (
        <div className={classes.loading}><CircularProgress /></div>
    ) : transScoreData.error ? (
        <div>{transScoreData.error}</div>
    ) :  (
        <div className={classes.card}>
            <TransScoreChart />
        </div>
    );
};

const mapStateToProps = (state) => ({
    transScoreData: state.research.transScoreData,
});

export default connect(mapStateToProps, {})(TransformationalScoreSummary);
