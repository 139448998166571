import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddEditThematic from './AddEditThematic';
import Typography from '@material-ui/core/Typography';
import { connect } from 'react-redux';
import Slide from '@material-ui/core/Slide/Slide';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {addThematic, updateThematic, deleteThematic} from "../../../../../actions/researchActions";
import ThemeCollapsibleTable from "./ThemeCollapsibleTable";
import AddEditCompetitor from "../Competitor/AddEditCompetitor";
import useMediaQuery from "@material-ui/core/useMediaQuery/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
    title: {
        color: '#649cb5',
        width: '100%',
        textAlign: 'center',
        paddingBottom: 8,
        paddingTop: 8,
    },
}));

const Thematic = ({loading,
                            error,
                            thematics,
                            addThematic,
                            updateThematic,
                            deleteThematic,}) => {
    const classes = useStyles();
    const mobile = useMediaQuery('(max-width:600px)');
    const [isAddMode, setIsAddMode] = React.useState(false);
    const [initialValues, setInitialValues] = React.useState({ idk_stock_themes: 0 });
    const [showAddEdit, setShowAddEdit] = React.useState(false);

    const handleAddEditClicked = (event) => {

        if (event.currentTarget.parentNode.parentNode.dataset.id) {
            let id = event.currentTarget.parentNode.parentNode.dataset.id;

            //grab score to send to add/edit
            let theme = thematics.filter(
                (theme) => String(theme.idk_stock_themes) === id
            );

            setInitialValues(theme[0]);
            setShowAddEdit(true);
            setIsAddMode(false);
        } else {
            setInitialValues({});
            setShowAddEdit(true);
            setIsAddMode(true);
        }
    };

    function onSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (isAddMode) {
            createInvTheme(values, resetForm);
        } else {
            updateInvTheme(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteThematic(values);
        setInitialValues({});
    }

    function createInvTheme(values, resetForm) {
        addThematic(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    function updateInvTheme(values, resetForm) {
        updateThematic(values);
        //setInitialValues({});
        resetForm({});
        setShowAddEdit(!showAddEdit);
    }

    const handleCancel = () => {
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    };

    return loading ? (<div className={classes.loading}><CircularProgress /></div>) :
             error ? (<div>{error}</div>) : (
        <div>
            <div className={classes.title}>
                <Typography variant="h6">Thematic</Typography>
            </div>
            {!showAddEdit ? (
                <div>
                  <ThemeCollapsibleTable
                      mobile={mobile}
                       handleAddEditClicked={handleAddEditClicked}
                       thematicList={thematics}
                   />
                </div>
            ) : (
                <Slide direction="left" in={true} mountOnEnter unmountOnExit>
                    <div>
                        <AddEditThematic
                            mobile={mobile}
                            initialValues={initialValues}
                            setShowAddEdit={setShowAddEdit}
                            onSubmit={onSubmit}
                            handleDelete={handleDelete}
                            handleCancel={handleCancel}
                        />
                    </div>
                </Slide>
            )}
        </div>
    );
};

Thematic.propTypes = {};

const mapStateToProps = (state) => ({
    loading: state.research.thematicData.loading,
    error: state.research.thematicData.error,
    thematics: state.research.thematicData.thematics,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addThematic: (invThematic) => dispatch(addThematic(invThematic)),
        updateThematic: (invThematic) => dispatch(updateThematic(invThematic)),
        deleteThematic: (invThematic) => dispatch(deleteThematic(invThematic))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(Thematic);
