import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

function Row(props) {
    const { row, handleAddEditClicked } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow data-id={row.idk_stock_themes} hover={true} className={classes.root}>
                    <TableCell padding='none'>
                        <IconButton   aria-label="expand row" size="medium" >
                            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                        </IconButton>
                    </TableCell>
                    <TableCell onClick={() => setOpen(!open)} >{row.secondary_theme_name}</TableCell>
                    <TableCell onClick={() => setOpen(!open)} >{row.weight}</TableCell>
                <TableCell style={{textAlign: "end"}} padding='none' >
                    <IconButton style={{marginRight: 5}} aria-label="edit" onClick={handleAddEditClicked} >
                        <EditIcon  style={{fontSize: 'medium'}}/>
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={2}>
                            <Typography style={{paddingLeft: 30, paddingRight: 30}} variant='body2'>
                                {row.note}
                            </Typography>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {

};

export default function ThemeCollapsibleTable(props) {
    return (
        <Fragment>
        <TableContainer component={Paper}>
            <Table stickyHeader size={props.mobile ? "small" : "medium"} aria-label="thematic table">
                <TableHead>
                    <TableRow>
                        <TableCell style={{border: "none"}}/>
                        <TableCell style={{border: "none"}}>Theme</TableCell>
                        <TableCell style={{border: "none"}}>Weight</TableCell>
                        <TableCell style={{border: "none", textAlign: "end"}} padding='none'>
                            <IconButton
                                    style={{fontSize: "medium"}}
                                    disabled={props.showAddEdit}
                                    onClick={props.handleAddEditClicked}
                                    aria-label="add"
                                >
                                    <AddIcon />
                                </IconButton>
                        </TableCell>
                          </TableRow>
                </TableHead>
                {props.thematicList.length > 0 ?
                    <TableBody>
                        {props.thematicList.map((row) => (
                            <Row key={row.idk_stock_themes}
                                 row={row}
                                 handleAddEditClicked={props.handleAddEditClicked}/>
                        ))}
                    </TableBody> :  null
                }
            </Table>
            </TableContainer>
            {!props.thematicList.length > 0 ?
                <Typography variant={"body1"}
                            style={{padding: 10, textAlign: "center"}}>
                    No Thematic Data
                </Typography> : null
            }
            </Fragment>
    );
}
