import config from '../config';
import jwt from 'jwt-decode';

const TokenService = {
    saveAuthToken(token) {
        window.sessionStorage.setItem(config.TOKEN_KEY, token);
    },
    getAuthToken() {
        return window.sessionStorage.getItem(config.TOKEN_KEY);
    },
    clearAuthToken() {
        window.sessionStorage.removeItem(config.TOKEN_KEY);
    },
    hasAuthToken() {
        return !!TokenService.getAuthToken();
    },
    makeBasicAuthToken(userName, password) {
        return window.btoa(`${userName}:${password}`);
    },
    decodeToken() {
        const user = jwt(TokenService.getAuthToken());
        console.log(user);
    },

    getCurrentUser(){
        return window.sessionStorage.getItem('currentUserValue');
    }
};

export default TokenService;
