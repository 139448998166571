import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PublicOnlyRoute from './Routes/PrivatePublic/PublicOnlyRoute';
import LoginPage from './Routes/LoginPage/LoginPage';
import ChangePasswordPage from './Routes/LoginPage/ChangePasswordPage';
import AdminRoute from './Routes/PrivatePublic/AdminRoute';
import NotFoundPage from './Routes/NoPageFound/NotFoundPage';
import InvestmentUniversePage from './Routes/InvestmentUniversePage/InvestmentUniversePage';
import PrivateRoute from './Routes/PrivatePublic/PrivateRoute';
import StockDetail from './components/InvestmentUniverse/InvestmentDetail';
import UserAdminCreatePage from './Routes/UserAdmin/UserAdminCreatePage';
import UserAdminUpdatePage from './Routes/UserAdmin/UserAdminUpdatePage';

function App(props) {
    return (
        <div>
            <main>
                <Switch>
                    <PrivateRoute
                        exact
                        path={'/'}
                        component={InvestmentUniversePage}
                    />
                    {/*<Route exact path={'/'} component={InvestmentUniversePage} />*/}
                    <PublicOnlyRoute
                        exact
                        path={'/login'}
                        component={LoginPage}
                    />
                    <AdminRoute
                        exact
                        path={'/useradmin/create'}
                        component={UserAdminCreatePage}
                    />
                    <AdminRoute
                        exact
                        path={'/useradmin/update'}
                        component={UserAdminUpdatePage}
                    />
                    <PrivateRoute
                        exact
                        path={'/universe'}
                        component={InvestmentUniversePage}
                    />
                    <PrivateRoute
                        exact
                        path={'/changepassword'}
                        component={ChangePasswordPage}
                    />
                    <PrivateRoute
                        exact
                        path={'/universe/:idkInvestment'}
                        component={StockDetail}
                    />
                    {/*<PrivateRoute exact path={'/tasks'} component={TasksPage} />*/}
                    <Route component={NotFoundPage} />
                </Switch>
            </main>
        </div>
    );
}

const mapStateToProps = (state) => ({
    openModal: state.openModal,
});

export default connect(mapStateToProps, {})(App);
