import React, {Component} from 'react'
import AuthApiService from '../../services/auth-api-service'
import {Button, TextField, Box} from "@material-ui/core";

export default class CreateUserForm extends Component {
    state = {error: null, success: null}
    // static defaultProps = {
    //     onRegistrationSuccess: () => {
    //     }
    // }

    onCreateSuccess = (user) =>{
        this.setState({error: null,
            success: 'New user '+user.user_name+' has been created!'})
    }

    handleSubmit = ev => {
        ev.preventDefault()
        const {full_name, user_name, password, email} = ev.target

        this.setState({error: null, success: null})

        AuthApiService.createUser({
            user_name: user_name.value,
            password: password.value,
            full_name: full_name.value,
            email: email.value,
        })
            .then(user => {
                full_name.value = ''
                user_name.value = ''
                password.value = ''
                email.value = ''
                this.onCreateSuccess(user)
            })
            .catch(res => {
                this.setState({...this.state, error: res.error})
            })
    }

    handleOnChange = () =>{
        this.setState({success: null})
    }

    render() {
        const {error} = this.state
        const {success} = this.state
        return (
            <div className='registration-container'>
                <form
                    className='registration-form'
                    onSubmit={this.handleSubmit}
                >
                    <div role='alert'>
                        {success && <p style={{color: 'green', fontWeight: 'bold'}} >{success}</p>}
                    </div>
                    <div role='alert'>
                        {error && <p style={{color: 'red'}} >{error}</p>}
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="RegistrationForm__full_name"
                            label="Full Name"
                            name="full_name"
                            autoFocus
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            //fullWidth
                            id="RegistrationForm__user_name"
                            label="User Name"
                            name="user_name"
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <div>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            id="RegistrationForm__password"
                            label="Password"
                            name="password"
                            type='password'
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <div className='email'>
                        <TextField
                            variant="outlined"
                            margin="normal"
                            required
                            width='90%'
                            id="RegistrationForm__email"
                            label="Email"
                            name="email"
                            onChange={this.handleOnChange}
                        />
                    </div>
                    <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}} m={5}>
                        <Button type="submit"
                                variant="contained"
                                color="primary"
                            // className={classes.submit}
                        >
                            Save
                        </Button>
                    </Box>
                </form>
            </div>
        )
    }
}
