import React from 'react';
import TranScoreAddEdit from './AddEditTranScore';
import { connect } from 'react-redux';
import { Grid, Paper, makeStyles } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Slide from '@material-ui/core/Slide';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {addTransScore, updateTransScore, deleteTransScore, } from "../../../../actions/researchActions";
import TransScoreCollapsibleTable from "./TransScoreCollapsibleTable";

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        width: '100%',
        height: '100vh',
        marginTop: 5,
    },
    gridPaper: {
        //padding: theme.spacing(1),
        color: theme.palette.text.secondary,
        //minHeight: 450,
    },
}));

const TransformationalScore = ({transScoreList,
                                loading,
                                error,
                                addTransScore,
                                updateTransScore,
                                deleteTransScore}) => {
    const classes = useStyles();
    //const theme = useTheme();
    const mobile = useMediaQuery('(max-width:600px)');

    const [isAddMode, setIsAddMode] = React.useState(true);
    const [initialValues, setInitialValues] = React.useState({});
    const [showAddEdit, setShowAddEdit] = React.useState(false);
    const [selectedIdkts, setSelectedIdkts] = React.useState(0);

    const handleAddEditClicked = (event) => {
        const id = event.currentTarget.dataset.id ? event.currentTarget.dataset.id :
            event.currentTarget.parentNode.parentNode.dataset.id
        if (id) {
            //grab score to send to add/edit
            let tranScore = transScoreList.filter(
                (score) => String(score.idkts) === id
            );

            setInitialValues(tranScore[0]);
            setShowAddEdit(true);
            setIsAddMode(false);
        } else {
            setInitialValues({});
            setShowAddEdit(true);
            setIsAddMode(true);
        }
    };

    function handleSubmit(values, { setStatus, resetForm }) {
        setStatus();
        if (isAddMode) {
            createScore(values, resetForm);
        } else {
            updateScore(values, resetForm);
        }
    }

    function handleDelete(values) {
        deleteTransScore(values);
        setInitialValues({});
    }

    function createScore(values, resetForm) {
            addTransScore(values);
            resetForm();
            setShowAddEdit(!showAddEdit);
    }

    function updateScore(values, resetForm) {
        updateTransScore(values);
        resetForm();
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    }

    const handleCancel = () => {
        setInitialValues({});
        setShowAddEdit(!showAddEdit);
    };

    return loading ? (<div className={classes.loading}><CircularProgress /></div>) :
             error ? (<div>{error}</div>) :
               (<div className={classes.gridRoot}>
                {!mobile ? (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.gridPaper}>
                            <TransScoreCollapsibleTable
                                mobile={mobile}
                                selectedIdkts={selectedIdkts}
                                setSelectedIdkts={setSelectedIdkts}
                                transScoreList={transScoreList}
                                handleAddEditClicked={handleAddEditClicked}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Paper className={classes.gridPaper}>
                            <TranScoreAddEdit
                                mobile={mobile}
                                initialValues={initialValues}
                                setShowAddEdit={setShowAddEdit}
                                handleSubmit={handleSubmit}
                                handleDelete={handleDelete}
                                handleCancel={handleCancel}
                            />
                        </Paper>
                    </Grid>
                </Grid>
            ) : (
                <Grid container spacing={1}>
                    {!showAddEdit ? (
                        <Grid item xs={12} sm={6}>
                            <Paper className={classes.gridPaper}>
                                <TransScoreCollapsibleTable
                                    mobile={mobile}
                                    selectedIdkts={selectedIdkts}
                                    setSelectedIdkts={setSelectedIdkts}
                                    transScoreList={transScoreList}
                                    handleAddEditClicked={handleAddEditClicked}
                                />
                            </Paper>
                        </Grid>
                    ) : (
                        <Grid item xs={12} sm={6}>
                            <Slide
                                direction="left"
                                in={true}
                                mountOnEnter
                                unmountOnExit
                            >
                                <Paper className={classes.gridPaper}>
                                    <TranScoreAddEdit
                                        mobile={mobile}
                                        initialValues={initialValues}
                                        setShowAddEdit={setShowAddEdit}
                                        handleSubmit={handleSubmit}
                                        handleDelete={handleDelete}
                                        handleCancel={handleCancel}
                                    />
                                </Paper>
                            </Slide>
                        </Grid>
                    )}
                </Grid>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    loading: state.research.transScoreData.loading,
    error: state.research.transScoreData.loading.error,
    transScoreList: state.research.transScoreData.stockTransScore.transScoreList,
});

const mapDispatchToProps = (dispatch) => {
    return {
        addTransScore: (invTransScore) => dispatch(addTransScore(invTransScore)),
        updateTransScore: (invTransScore) => dispatch(updateTransScore(invTransScore)),
        deleteTransScore: (invTransScore) => dispatch(deleteTransScore(invTransScore))
    }
};

export default connect(mapStateToProps, mapDispatchToProps)(TransformationalScore);
