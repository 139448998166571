import React from 'react';
import { getIn } from 'formik';
import MatTextField from '@material-ui/core/TextField';
// import { fieldShape, formShape } from '../utils/PropTypes';

class TD3TextField extends React.Component {
    handleChange = (event) => {
        this.props.field.onChange(event);
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    };

    handleBlur = (event) => {
        this.props.field.onBlur(event);
        if (this.props.onBlur) {
            this.props.onBlur(event);
        }
    };

    render() {
        const {
            field,
            form: { touched, errors },
            helperText,
            children,
            ...props
        } = this.props;
        const message = getIn(touched, field.name) && getIn(errors, field.name);
        return (
            <MatTextField
                {...props}
                {...field}
                margin="none"
                error={Boolean(message)}
                helperText={message || helperText}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
            >
                {children}
            </MatTextField>
        );
    }
}

// if (process.env.NODE_ENV !== 'production') {
//     TD3TextField.propTypes = {
//         field: fieldShape.isRequired,
//         form: formShape.isRequired,
//         helperText: PropTypes.node,
//         children: PropTypes.node,
//         onChange: PropTypes.func,
//         onBlur: PropTypes.func,
//     };
// }

export default TD3TextField;
