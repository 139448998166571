import config from "../config";
import TokenService from "./token-service";

const ResearchApiService = {
    getOptionValues() {
        return fetch(`${config.API_ENDPOINT}api/research/selectOptionValues`, {
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
            },
        })
            .then(res =>{
                return res.json()
            })
    },
    getUniverse() {
        return fetch(`${config.API_ENDPOINT}api/research/universe`, {
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
            },
        }).then((res) => {
            if (!res.ok) {
                throw Error(res.statusText);
            } else if (res.status === 204) {
                const nodata = { info: 'No Investment for  for current user' };
                return nodata;
            } else return res.json();
        });
    },
    ///////////////////////////////////////////////////////////////
    // Big Move
    ///////////////////////////////////////////////////////////////
    getInvBigMove(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invbigmove/${idkInvestment}`, {
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
            },
        })
            .then(res =>{
                return res.json()
            })
    },
    addInvBigMove(values) {
        // these values are handled by the API
        delete values.idkDD_stock;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invbigmove/${values.idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    updateInvBigMove(values){
        // these values are handled by the API
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invbigmove/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    deleteInvBigMove(values){
        const idkInvestment = values.idkInvestment;
        delete values.idkInvestment;
        return fetch(`${config.API_ENDPOINT}api/research/invbigmove/${idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    ///////////////////////////////////////////////////////////////
    // General Note
    ///////////////////////////////////////////////////////////////
    getInvGenNote(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invgennote/${idkInvestment}`, {
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
            },
        })
            .then(res =>{
                return res.json()
            })
    },
    addInvGenNote(values) {
        // these values are handled by the API
        delete values.idkDD_stock;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invgennote/${values.idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    updateInvGenNote(values){
        // these values are handled by the API
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invgennote/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    deleteInvGenNote(values){
        const idkInvestment = values.idkInvestment;
        delete values.idkInvestment;
        return fetch(`${config.API_ENDPOINT}api/research/invgennote/${idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    ///////////////////////////////////////////////////////////////
    // PASS FAIL
    ///////////////////////////////////////////////////////////////
    getInvPassFail(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invpassfail/${idkInvestment}`, {
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
            },
        })
            .then(res =>{
                return res.json()
            })
    },
    updateInvPassFail(values){
        delete values.editedby;
        delete values.enteredby;
        return fetch(`${config.API_ENDPOINT}api/research/invpassfail/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    ///////////////////////////////////////////////////////////////
    // TRANSFORMATION SCORE
    ///////////////////////////////////////////////////////////////
    getInvTransScore(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invscore/${idkInvestment}`,
            {
                headers: {
                    authorization: `bearer ${TokenService.getAuthToken()}`,
                },
            }
        )
        .then(res =>{
            if(res.ok){
                return res.json()
            }else {
                console.log(res)
                throw Error(res.status+' - '+res.statusText+' - '+res.url)
            }
        })
    },
    addInvTransScore(values){
        // these values are handled by the API
        delete values.idkts;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invscore/${values.idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    updateInvTransScore(values){
        // these values are handled by the API
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invscore/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    deleteInvTransScore(values){
        const idkInvestment = values.idkInvestment;
        delete values.idkInvestment;
        return fetch(`${config.API_ENDPOINT}api/research/invscore/${idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    ///////////////////////////////////////////////////////////////
    // DUE DILIGENCE
    ///////////////////////////////////////////////////////////////
    getInvDueDiligence(idkInvestment){
        return fetch(`${config.API_ENDPOINT}api/research/invduediligence/${idkInvestment}`,
            {
                headers: {
                    authorization: `bearer ${TokenService.getAuthToken()}`,
                },
            }
        )
            .then(res =>{
                if(res.ok){
                    return res.json()
                }else {
                    throw Error(res.statusText)
                }
            })
    },
    addInvDueDiligence(values){
        // these values are handled by the API
        delete values.idkDD_stock;
        delete values.idkDD_Memo;
        delete values.idkts;
        delete values.editedby;
        delete values.enteredby;
        const idkInvestment = values.idkInvestment.investment;

        return fetch(`${config.API_ENDPOINT}api/research/invduediligence/${idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    updateInvDueDiligence(values){
        // these values are handled by the API
        delete values.editedby;
        delete values.enteredby;
        const idkInvestment = values.idkInvestment.investment;

        return fetch(`${config.API_ENDPOINT}api/research/invduediligence/${idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },
    deleteInvDueDiligence(values){
        const idkInvestment = values.idkInvestment.investment;
        delete values.idkInvestment;
        return fetch(`${config.API_ENDPOINT}api/research/invduediligence/${idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        }).then((res) => {
            if(res.ok){
                return res.json()
            }else {
                throw Error(res.statusText)
            }
        });
    },

    getInvThematic(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invtheme/${idkInvestment}`,
            {
                headers: {
                    authorization: `bearer ${TokenService.getAuthToken()}`,
                },
            })
            .then(checkError)
            .then(data =>{
                return data
            })
    },
    addInvThematic(values) {
        // these values are handled by the API
        delete values.idk_stock_themes;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invtheme/${values.idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
        .then(checkError)
        .then(data => {
            return data
        });
    },
    updateInvThematic(values) {
        // these values are handled by the API
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invtheme/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(checkError)
            .then(data => {
                return data
            });
    },
    deleteInvThematic(values){
        return fetch(`${config.API_ENDPOINT}api/research/invtheme/${values.idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
        .then(checkError)
        .then(data => {
            return data
        });
    },

    getInvCompetitor(idkInvestment) {
        return fetch(`${config.API_ENDPOINT}api/research/invcomp/${idkInvestment}`,
            {
                headers: {
                    authorization: `bearer ${TokenService.getAuthToken()}`,
                },
            })
            .then(checkError)
            .then(data =>{
                return data
            })
    },
    addInvCompetitor(values) {
        // these values are handled by the API
        delete values.idk_stock_competitors;
        delete values.Competitor_Name;
        delete values.Competitor_Ticker;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invcomp/${values.idkInvestment}`, {
            method: 'POST',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(checkError)
            .then(data => {
                return data
            });
    },
    updateInvCompetitor(values) {
        // these values are handled by the API
        delete values.Competitor_Name;
        delete values.Competitor_Ticker;
        delete values.editedby;
        delete values.enteredby;

        return fetch(`${config.API_ENDPOINT}api/research/invcomp/${values.idkInvestment}`, {
            method: 'PATCH',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(checkError)
            .then(data => {
                return data
            });
    },
    deleteInvCompetitor(values){
        return fetch(`${config.API_ENDPOINT}api/research/invcomp/${values.idkInvestment}`, {
            method: 'DELETE',
            headers: {
                authorization: `bearer ${TokenService.getAuthToken()}`,
                'content-type': 'application/json',
            },
            body: JSON.stringify(values),
        })
            .then(checkError)
            .then(data => {
                return data
            });
    },


};

const checkError = (response) => {
    if (!response.ok) {
        if(process.env.NODE_ENV === 'development' ){
            response.text().then(text => {
                console.log(text)
            });
        }
        throw Error(response.status+ ' ' +response.statusText +' '+response.url)
    }
    return response.json();
}

export default ResearchApiService;
